import { connect } from "react-redux";
import {
  changeBirthDate,
  submitRecomendationForm,
  changeTaskChecked,
  showEmptyFields,
  addSetToCharacteristic,
  changeDateOfEnrollment,
  changeDateOfFIlling,
  changeValueByNameOnTopLevel,
  setCheckedEstimateOptions,
  setCheckedSpecialists,
  changeScheduleStartTime,
  changeScheduleFinishTime,
  changeSpecialTrainingConditionsAnother,
  setSelectedByName,
  setSurveySelected,
  changeSurveyAnother,
  setNeedToAddSpecialist,
  changeSpecialistOptionToAdd,
  addSpecialistsOption,
  returnToDefaultSet,
  changeCharacteristicIsDone,
  save,
  load,
} from "../../redux/recommendationFormReducer";
import RecommendationForm from "./RecommendationForm";

const mapStateToProps = (state) => ({
  formData: state.recommendation,
  token: state.auth.token,
  userId: state.auth.userId,
});

const mapDispatchToProps = {
  submitRecomendationForm,
  changeBirthDate,
  showEmptyFields,
  changeTaskChecked,
  addSetToCharacteristic,
  changeDateOfEnrollment,
  changeDateOfFIlling,
  changeValueByNameOnTopLevel,
  setCheckedEstimateOptions,
  setCheckedSpecialists,
  changeScheduleStartTime,
  changeScheduleFinishTime,
  changeSpecialTrainingConditionsAnother,
  setSelectedByName,
  setSurveySelected,
  changeSurveyAnother,
  setNeedToAddSpecialist,
  changeSpecialistOptionToAdd,
  addSpecialistsOption,
  returnToDefaultSet,
  changeCharacteristicIsDone,
  save,
  load,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationForm);
