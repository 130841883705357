import React from 'react'
import { Link } from 'react-router-dom'
import css from './Header.module.scss'
import IKPLogo from '../../img/IKPLogo.png'
import EagleLogo from '../../img/EagleLogo.png'
import SendMail from '../../img/SendMail.png'

const Header = (props) => {
  const links = props.isLogin ? (
    <>
      <Link
        className={css.link}
        to={props.path === '/cabinet' ? '/' : '/cabinet'}
      >
        {props.path === '/cabinet' ? 'Главная' : 'Личный кабинет'}
      </Link>
      <span className={css.link} onClick={props.logout}>
        Выйти
      </span>
    </>
  ) : (
    <>
      <Link className={css.link} to="/login">
        Войти
      </Link>
      <Link className={css.link} to="/registration">
        Зарегистрироваться
      </Link>
    </>
  )

  return (
    <header className={css.header}>
      <div className={`${css.container}`}>
        <div className={css.col}>
          <Link to="/">
            <div className={css.logo}>
              <img src={EagleLogo} />
            </div>
          </Link>
          <Link to="/">
            <div className={css.logo}>
              <img src={IKPLogo} />
            </div>
          </Link>
        </div>

        <div className={css.col}>
          <a
            href="mailto:lab_abilitatsii@ikp.email"
            className={`${css.sendMail} ${css.link}`}
          >
            <div className={css.sendMail__icon}>
              <img src={SendMail} alt="SendMail" />
            </div>
            <p>
              <span className={css.sendMail__email}>
                lab_abilitatsii@ikp.email
              </span>
              <br />
              Отправить письмо
            </p>
          </a>
        </div>
        <div className={css.col}>
          <nav className={css.nav}>{links}</nav>
        </div>
      </div>
    </header>
  )
}

export default Header
