const dateToRussianFormat = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }
  return new Date(dateString).toLocaleString('ru', options)
}

export default dateToRussianFormat
