import React from "react";
import Spoiler from "../Spoiler/Spoiler";

const FamilyResoucesPreview = (props) => {
  const {
    financialSituation,
    livingConditions,
    parentsEducation,
    familyStatus,
    workingFamilyMembers,
  } = props;

  const isVisible = financialSituation && livingConditions && parentsEducation && familyStatus;

  function chooseFamilyResourcesPreview() {
    let familyRating = 0;

    if (workingFamilyMembers > 0) {
      familyRating++;
    }

    if (parentsEducation === "Высшее" && parentsEducation === "Неоконченное высшее") {
      familyRating++;
    }

    if (
      livingConditions === "Отдельная благоустроенная квартира/дом с удобствами" ||
      livingConditions === "Отдельная квартира/дом без удобств" ||
      livingConditions === "Более одной комнаты в коммунальной квартире с удобствами"
    ) {
      familyRating++;
    }

    if (
      financialSituation ===
        "Высокий материальный уровень, достаточные материальные возможности для социальной, медико-психологической реабилитации ребенка" ||
      financialSituation ===
        "Средний материальный уровень, доступны платные медицинские услуги в интересах ребенка"
    ) {
      familyRating++;
    }

    return familyRating >= 3 ? (
      <Spoiler summary="Достаточные социально-экономические ресурсы">
        <div>
          <p>
            Активное вовлечение членов семьи в процесс целенаправленной образовательной деятельности
            и внеклассной активности, установление партнерских отношений с родителями.{"\n"}
            Психолого-педагогическое консультирование по вопросам реализации содержания программы и
            организации развивающих условий на дому
          </p>
        </div>
      </Spoiler>
    ) : (
      <Spoiler summary="Недостаточные социально экономические ресурсы">
        <div>
          <p>
            Учет в процессе обучения ребенка ограниченных ресурсов семьи в процессе целенаправленной
            образовательной деятельности и создании развивающей среды в домашних условиях.
            Систематическое включение родителей в различные мероприятия: занятия с ребенком
            «специалист – ребенок – родитель», участие в тематических семинарах-тренингах, досуговой
            активности).
          </p>
        </div>
      </Spoiler>
    );
  }

  return <>{!isVisible ? null : chooseFamilyResourcesPreview()}</>;
};

export default FamilyResoucesPreview;
