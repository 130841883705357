import React from 'react'
import './Info.scss'
import SmartPhone from '../../img/smartphone.svg'
import instruction from './instruction.pdf'

const Info = () => {
  return (
    <div className="info">
      <div className="info__wrapper">
        <div className="info__text">
          <h1 className="info__title">
            Конструктор специальной индивидуальной программы развития для детей
            раннего и дошкольного возраста с ТМНР:
          </h1>
          <p className="info__description">
            <ul className="info__list">
              <li>
                • Автоматизированная обработка медико-социальных и
                психолого-педагогических данных.
              </li>
              <li>• Мониторинг результатов обучения.</li>
              <li>• Хранение и поиск информации.</li>
            </ul>
            <div>
              <a
                href={instruction}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <button class="waves-effect info__button transparent orange-text z-depth-0 btn">
                  <div>Скачать инструкцию</div>
                </button>
              </a>
            </div>
          </p>
        </div>
        <div className="info__picture">
          <img src={SmartPhone} alt="smartphone.svg" />
        </div>
      </div>
    </div>
  )
}

export default Info
