import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@material-ui/core'
import React from 'react'
import MultipleSelect from '../MultipleSelect/MultipleSelect'
import SingleSelect from '../SingleSelect/SingleSelect'

const Survey = (props) => {
  const {
    id,
    title,
    psychologicalAchievements,
    pathologicalBehavior,
  } = props
  const selectHandler = (event) => {
    if (!event.target.value.includes(undefined)) {
      props.selectHandler(event.target.name, event.target.value)
    }
  }

  const anotherChangeHandler = (event) => {
    props.anotherChangeHandler(event.target.name, event.target.value)
  }

  const createAchievmentSelect = () => {
    let options = []
    psychologicalAchievements.sets.forEach((s) => {
      options = [...options, ...s.options]
    })

    return (
      <SingleSelect
        label="Психологические достижения возраста"
        id={'psychologicalAchievements' + id}
        name="psychologicalAchievements"
        selected={psychologicalAchievements.selected}
        onChange={selectHandler}
        options={[...options, "Другое"]}
        textClassName="green-text"
      />
    )
  }

  const createPathologiesSelect = () => {
    let options = []
    pathologicalBehavior.sets.forEach((s) => {
      options = [...options, ...s.options]
    })

    return (
      <MultipleSelect
        label="Патологическое поведение"
        id={'pathologicalBehavior' + id}
        name="pathologicalBehavior"
        selected={pathologicalBehavior.selected}
        onChange={selectHandler}
      >
        {options.map((ro) => {
          return (
            <MenuItem key={ro} value={ro}>
              <ListItemIcon>
                <Checkbox
                  className="orange-text"
                  checked={pathologicalBehavior.selected.includes(ro)}
                />
              </ListItemIcon>
              <ListItemText className="red-text" primary={ro} />
            </MenuItem>
          )
        })}

        <MenuItem value={'Другое'}>
          <ListItemIcon>
            <Checkbox
              className="orange-text"
              checked={pathologicalBehavior.selected.includes('Другое')}
            />
          </ListItemIcon>
          <ListItemText className="red-text" primary={'Другое'} />
        </MenuItem>
      </MultipleSelect>
    )
  }

  return (
    <div className="row">
      <div className="col s12">
        <h4>{title}</h4>
      </div>
      <div className="col s12">{createAchievmentSelect()}</div>

      {psychologicalAchievements.selected === 'Другое' ? (
        <div className="input-field col s12">
          <input
            id={'psychologicalAchievements' + id + 'input'}
            name="psychologicalAchievements"
            type="text"
            value={psychologicalAchievements.another}
            onChange={anotherChangeHandler}
            autoComplete='off'
          />
          <label htmlFor={'psychologicalAchievements' + id + 'input'}>
            Другое
          </label>
        </div>
      ) : null}

      <div className="col s12">{createPathologiesSelect()}</div>

      {pathologicalBehavior.selected.includes('Другое') ? (
        <div className="input-field col s12">
          <input
            id={'pathologicalBehavior' + id + 'input'}
            name="pathologicalBehavior"
            type="text"
            value={pathologicalBehavior.another}
            onChange={anotherChangeHandler}
            autoComplete='off'
          />
          <label htmlFor={'pathologicalBehavior' + id + 'input'}>Другое</label>
        </div>
      ) : null}
    </div>
  )
}

export default Survey
