import React, { useEffect } from "react";
import M from "materialize-css";
import { Link } from "react-router-dom";

const Login = (props) => {
  const { submitLogin, email, password, changePassword, isFetching, message, changeEmail, clear } = props;

  useEffect(() => {
    M.AutoInit();
  }, []);


  return (
    <div className="row">
      <h3 className="col s12"> Авторизация</h3>
      <form
        className="col s12"
        onSubmit={(event) => {
          event.preventDefault();
          submitLogin(email, password);
        }}
      >
        <div className="row">
          <div className="input-field col s12">
            <input
              id="email"
              name="email"
              type="email"
              className="validate"
              value={email}
              onChange={(event) => changeEmail(event.target.value.toLowerCase())}
              required
            />
            <label htmlFor="email">Email</label>
            <span className="helper-text" data-error="Некорректный email" data-success="">
              Введите email
            </span>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12">
            <input
              id="password"
              type="password"
              name="password"
              className="validate"
              minLength="8"
              value={password}
              onChange={(event) => changePassword(event.target.value)}
              required
            />
            <label htmlFor="password">Пароль</label>
            <span className="helper-text" data-error="Короткий пароль" data-success="">
              Введите пароль (минимум 8 символов)
            </span>
          </div>
          <div className="col s12">
            <span className="helper-text red-text" data-success="">
              {message}
            </span>
          </div>
        </div>

        <div className="row">
          <Link onClick={clear} to="/forgot-password" className="btn-outline btn-reg orange-text">
            Забыли пароль?
          </Link>
        </div>

        <div className="row">
          <button
            className="btn waves-effect orange waves-light authButton"
            type="submit"
            disabled={isFetching}
          >
            Войти
          </button>
          <Link onClick={clear} to="/registration" className="btn-outline btn-reg orange-text">
            Нет аккаута?
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
