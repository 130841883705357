const save = JSON.parse(localStorage.getItem("save"));

const initialState = {
  // SAVE
  save: save ? !!save : null,

  // ЦЕЛЕВОЙ РАЗДЕЛ

  dateOfEnrollment: "",
  dateOfFilling: "",
  birthDate: "",
  dynamics: {
    selected: "Без динамики",
    options: ["Без динамики", "Минимальная", "Средняя", "Высокая", "Нет сведений"],
  },
  age: null,
  basePeriod: null,
  firstName: "",
  lastName: "",
  typesOfRehabilitation: "",
  message: "",
  agreeWithPrivacyPolicy: false,

  // ОБСЛЕДОВАНИЯ И СПЕЦИАЛИСТЫ

  surveys: [
    {
      id: 1,
      title: "Характеристика эмоционального состояния в условиях группы",
      psychologicalAchievements: {
        selected: "",
        another: "",
        sets: [
          {
            period: 1,
            options: ["Спокойное состояние в ситуации комфорта; беспокойство, плач в ситуации дискомфорта"],
          },
          {
            period: 2,
            options: ["Дифференцированные мимические проявления (эмоции) при общении со взрослыми и на воздействие сенсорных раздражителей"],
          },
          {
            period: 3,
            options: ["Спокойное или положительное отношение к новизне (контакту с новым взрослым, действиям с игрушками)"],
          },
          {
            period: 4,
            options: ["Ориентировка на эмоциональное состояние взрослого"],
          },
        ],
      },
      pathologicalBehavior: {
        selected: [],
        another: "",
        sets: [
          {
            period: 1,
            options: ["Безразличное, не изменяется"],
          },
          {
            period: 2,
            options: ["Негативное отношение к новизне (крик, беспокойство)"],
          },
          {
            period: 3,
            options: ["Неустойчивое эмоциональное состояние"],
          },
          {
            period: 4,
            options: ["Страх новизны с постепенным угасанием по мере адаптации"],
          },
        ],
      },
    },
    {
      id: 2,
      title: "Характеристика зрительного восприятия",
      psychologicalAchievements: {
        selected: "",
        another: "",
        sets: [
          {
            period: 1,
            options: ["Несколько секунд фиксирует взгляд на лице взрослого и ярких крупных предметах"],
          },
          {
            period: 2,
            options: ["Поисковые и исследовательские движения взгляда при перемещении лица взрослого или ярких крупных предметов непосредственно в поле зрения"],
          },
          {
            period: 3,
            options: ["Зрительная ориентировка на внешний вид, форму и размер объекта", "Опознание объектов"],
          },
          {
            period: 4,
            options: ["Зрительная ориентировка в сенсорных свойствах предмета", "Зрительная ориентировка на расположение предметов в пространстве"],
          },
        ],
      },
      pathologicalBehavior: {
        selected: [],
        another: "",
        sets: [
          {
            period: "all",
            options: ["Не фиксирует взгляд на предметах и объектах"],
          },
        ],
      },
    },
    {
      id: 3,
      title: "Характеристика слухового восприятия",
      psychologicalAchievements: {
        selected: "",
        another: "",
        sets: [
          {
            period: 1,
            options: [
              "Отсутствует сторожевая реакция сосредоточения на предъявление громкого звука на расстоянии 0,5 м от уха",
              "Сторожевая реакция сосредоточения на громкий звук, на звучание громкой речи на расстоянии 0,5 м от уха",
            ],
          },
          {
            period: 2,
            options: [
              "Поисковая реакция на громкий звук, на громкую речь, человеческий голос",
              "Находит взглядом источник звука",
              "Улыбка или мимика удовольствия при звучании знакомой игрушки и голоса, речи близкого взрослого",
            ],
          },
          {
            period: 3,
            options: [
              "Ориентировочное поведение на обращенную речь в виде выполнения действия или движения по слову. Реакция на ласковую и строгую интонацию",
            ],
          },
          {
            period: 4,
            options: ["Понимание речевых инструкций, нахождение названного предмета и выполнение действий по слову", 'Выполнение простых речевых инструкций'],
          },
        ],
      },
      pathologicalBehavior: {
        selected: [],
        another: "",
        sets: [
          {
            period: "all",
            options: ["Крик при восприятии громких или новых звуков"],
          },
        ],
      },
    },
    {
      id: 4,
      title: "Характеристика движений",
      psychologicalAchievements: {
        selected: "",
        another: "",
        sets: [
          {
            period: 1,
            options: ["Принимает удобное положение в позе лежа на спине, на руках у взрослого, удерживает голову несколько секунд"],
          },
          {
            period: 2,
            options: ["Совершает поисковые движения головой и телом, поворачивается на бок, на живот"],
          },
          {
            period: 3,
            options: ["Меняет положение тела в пространстве, передвигается путем ползания"],
          },
          {
            period: 4,
            options: ["Ходит с опорой", "Самостоятельная дискоординированная ходьба"],
          },
        ],
      },
      pathologicalBehavior: {
        selected: [],
        another: "",
        sets: [
          {
            period: "all",
            options: ["Не принимает удобного положения в позе лежа на спине, на руках у взрослого", "Не удерживает голову"],
          },
        ],
      },
    },
    {
      id: 5,
      title: "Характеристика движения рук",
      psychologicalAchievements: {
        selected: "",
        another: "",
        sets: [
          {
            period: 1,
            options: ["Совершает дискоординированные движения руками и ногами", "Разжимает и сжимает кулачки, случайно захватывает пеленку"],
          },
          {
            period: 2,
            options: [
              "Поднимает руки и располагает их перед собой, направляет руки к предмету и захватывает его",
              "Совершает размахивающие движения руками",
              "Ощупывает руками поверхность предметов",
            ],
          },
          {
            period: 3,
            options: [
              "Использует жесты, движения рук для привлечения внимания взрослого, коммуникации с ним",
              "Захватывает игрушки и выполняет простые социальные действия с ними",
            ],
          },
          {
            period: 4,
            options: ["Владеет орудийными и предметными действиями"],
          },
        ],
      },
      pathologicalBehavior: {
        selected: [],
        another: "",
        sets: [
          {
            period: "all",
            options: ["Движения рук непроизвольные, не направлены на предмет", "Захват предмета не возможен из-за высокого тонуса мышц рук"],
          },
        ],
      },
    },
    {
      id: 6,
      title: "Характеристика социально-коммуникативного развития в общении с близким взрослым",
      psychologicalAchievements: {
        selected: "",
        another: "",
        sets: [
          {
            period: 1,
            options: ["Успокаивается на руках у матери. Улыбается во время общения с ней"],
          },
          {
            period: 2,
            options: ["Повышением двигательной активности, поисковыми движениями головы, мимикой и звуками гуления инициирует общение"],
          },
          {
            period: 3,
            options: [
              "Мимикой, жестами, интонацией, звуками инициирует общение и информирует о физиологических и социальных потребностях, желаниях. Владеет элементами самостоятельности",
            ],
          },
          {
            period: 4,
            options: [
              "Использует жестово-мимические средства с целью коммуникации, отдельные слова и простые фразы",
              "Самостоятелен в одевании, приеме пищи, гигиене при небольшой внешней практической помощи",
            ],
          },
        ],
      },
      pathologicalBehavior: {
        selected: [],
        another: "",
        sets: [
          {
            period: "all",
            options: ["Кричит, когда его одевают/раздевают", "Принимает пищу дробно в силу истощаемости сосания", "Избирательность в еде"],
          },
        ],
      },
    },
    {
      id: 7,
      title: "Характеристика социально-коммуникативного развития в общении с детьми",
      psychologicalAchievements: {
        selected: "",
        another: "",
        sets: [
          {
            period: 1,
            options: ["Проявляет кратковременный интерес к ребенку и рассматривает его"],
          },
          {
            period: 2,
            options: ["Длительно рассматривает ребенка, направляет руки в его сторону, мимикой и звуками гуления инициирует общение"],
          },
          {
            period: 3,
            options: [
              "Мимикой, жестами, интонацией, звуками инициирует общение, спокойно играет рядом, протягивает предметы, копирует действия ребенка",
            ],
          },
          {
            period: 4,
            options: [
              "С помощью жестово-мимических средств инициирует общение и совершает попытки сотрудничества и совместных действий (дает предметы, прикасается, играет рядом, подражает действиям другого)",
            ],
          },
        ],
      },
      pathologicalBehavior: {
        selected: [],
        another: "",
        sets: [
          {
            period: "all",
            options: ["Кричит при виде другого ребенка", "Толкает, бьет"],
          },
        ],
      },
    },
    {
      id: 8,
      title: "Способ усвоения общественного опыта",
      psychologicalAchievements: {
        selected: "",
        another: "",
        sets: [
          {
            period: 1,
            options: ["Совместные действия"],
          },
          {
            period: 2,
            options: ["Совместно-разделенные действия, имитация"],
          },
          {
            period: 3,
            options: ["Копирование, новое действие выполняет после обучения рука в руку, знакомые действия по показу"],
          },
          {
            period: 4,
            options: [
              "Подражает практическим действиям взрослого с игрушкой. Выполняет простую жестовую или речевую инструкцию",
            ],
          },
        ],
      },
      pathologicalBehavior: {
        selected: [],
        another: "",
        sets: [
          {
            period: "all",
            options: ["Кричит, когда взрослый берет его руки, отказывается от совместных действий", "Действует с предметом стереотипно"],
          },
        ],
      },
    },
  ],

  recommendedProgram: "",
  socialCategory: {
    selected: [],
    options: ["Инвалид", "Ограниченные возможности здоровья"],
  },
  hasIPRA: false,
  healthStatus: {
    selected: [],
    options: [
      "Болезни эндокринной системы, расстройства питания и нарушения обмена веществ",
      "Болезни нервной системы",
      "Болезни глаза и его придаточного аппарата",
      "Болезни уха и сосцевидного отростка",
      "Болезни органов дыхания",
      "Отдельные состояния, возникающие в перинатальном периоде",
      "Врожденные аномалии (пороки развития), деформации и хромосомные нарушения",
      "Травмы, отравления и некоторые другие последствия  воздействия  внешних причин",
      "Психические расстройства и расстройства поведения",
      "Прочие болезни",
    ],
    estimations: [
      {
        id: 1,
        title: "Педагогическая оценка состояния слуха",
        categories: ["Болезни уха и сосцевидного отростка"],
        selected: [],
        options: [
          "Отсутствие сторожевой реакции (сосредоточения на громкий звук)",
          "Наличие сторожевой реакции (сосредоточения на громкий звук)",
          "Локализация звука в пространстве (поворот головы в сторону источника звука, нахождение его взглядом)",
          "Дифференциация неречевых и речевых звуков (изменение поведения и мимики при звучании знакомого и незнакомого)",
          "Понимание нескольких речевых образцов/слов/фраз (выполнение действия, нахождение предмета)",
          "Понимание нескольких простых речевых конструкций (выполнение просьбы, действия с предметом)",
        ],
      },
      {
        id: 2,
        title: "Педагогическая оценка состояния зрения",
        categories: ["Болезни глаза и его придаточного аппарата"],
        selected: [],
        options: [
          "Отсутствие сторожевой реакции (сосредоточения на свет)",
          "Наличие сторожевой реакции (сосредоточения на свет)",
          "Фиксация взгляда на контрастном подсвеченном предмете из любого положения",
          "Прослеживание за контрастным подсвеченным предметом; узнавание",
          "Зрительный контроль движения предметов и руки взглядом",
          "Зрительная ориентировка в пространстве и контроль движений при перемещении, выполнении действий с предметами",
          "Узнавание крупных контрастных предметов и изображений; дифференциация предметов по их внешнему виду",
          "Зрительная ориентировка на сенсорный признак предмета цвет, отдельные детали и части",
        ],
      },
      {
        id: 3,
        title: "Педагогическая оценка состояния движений общих",
        categories: ["Болезни нервной системы", "Травмы, отравления и некоторые другие последствия  воздействия  внешних причин"],
        selected: [],
        options: [
          "Дискоординированные движения",
          "Управления движениями головы",
          "Простое управление движениями тела (поворот)",
          "Cложное управление движениями тела (повороты для исследования окружающей среды с живота на спину и со спины на живот, подъем тела с опорой на таз и предплечья)",
          "Перемещение в пространстве с помощью ползания",
          "Произвольное изменение позы (лежа, сидя, стоя у опоры)",
          "Ходьба у опоры и с опорой",
          "Самостоятельное передвижение в пространстве на небольшое расстояние (3-5 метров)",
          "Самостоятельная ходьба",
        ],
      },
      {
        id: 4,
        title: "Педагогическая оценка состояния движений рук",
        categories: ["Болезни нервной системы", "Травмы, отравления и некоторые другие последствия  воздействия  внешних причин"],
        selected: [],
        options: [
          "Дискоординированные движения рук",
          "Направленные движения рук к предмету с захватом из положения лежа на спине",
          "Захват предмета из любого положения с распределением пальцев по его поверхности",
          "Придание кисти руки и пальцам удобного положения для захвата и выполнения действий с предметом",
          "Захват предмета с учетом его размера и социального назначения, планируемого действия",
          "Выполнение соотносящих действий",
          "Выполнение орудийных действий",
        ],
      },
      {
        id: 5,
        title: "Педагогическая оценка состояния эмоционально-волевой сферы",
        selected: [],
        categories: ["Болезни нервной системы", "Психические расстройства и расстройства поведения"],
        options: [
          "Отсутствие эмоционального ответа на изменение психофизического состояния (дискомфорт и комфорт)",
          "Мимические проявления и двигательная активность, голосовые реакции отражают психофизическое состояние (мимика удовольствия и гримаса страдания, плач)",
          "Успокоение при тактильном контакте с матерью, звучании ее голоса",
          "Дифференциация эмоций в соответствии с психофизическим состоянием и ситуацией",
          "Согласование эмоционального состояния с эмоциональным состоянием, действиями и поведением других людей, социальным контекстом, результатом деятельности",
        ],
      },
    ],
  },
  specialists: {
    selected: [],
    options: [
      "Учитель-дефектолог",
      "Учитель-логопед",
      "Тифлопедагог",
      "Сурдопедагог",
      "Инструктор АФК",
      "Тьютор/ассистент",
      "Педагог-психолог",
      "Социальный педагог",
    ],
    schedules: [],
    needToAddOption: false,
    optionToAdd: "",
  },

  needTechnicalEquipment: false,

  technicalEquipment: {
    selected: [],
    options: [
      "Мультимедийная система (интерактивная доска, проектор)",
      "Компьютер, доступ к сети-интернет",
      "FM-системы для индивидуальной и групповой работы",
      "Кресло-коляска",
      "Трость",
      "Опоры",
      "Подъемники",
      "Специальная парта (стол) с выемкой и регулируемой высотой",
      "Специальный стул на колесах и высокой спинкой",
      "Выделенные столы в аудиториях, к которым можно свободно подъехать на коляске",
      "Вертикализаторы",
      "Средства для альтернативной коммуникации (планшеты)",
      "Специальное кресло с головодержателем для учащихся с ДЦП",
      "Подлокотник для учащихся с ДЦП",
      "Специальная компьютерная клавиатура с крупными кнопками",
      "Специальный джойстик для компьютера для учащихся с ДЦП",
      "Специально оборудованные для учащихся с ДЦП мастерские, лаборатории",
      "Специально оборудованный зал ЛФК (тренажерный зал)",
      "Компьютерная техника с возможностью работы на шрифте Брайля",
      "Электронная лупа",
      "Специальное оборудование для глухих детей",
      "Специальное оборудование для слабослышащих детей",
      "Специальное оборудование физкультурно-спортивных площадок и площадок для подвижных игр и отдыха",
      "Нет перечисленного оборудования в наличии",
    ],
  },
  specialTrainingConditions: {
    selected: [],
    another: "",
    options: [
      "Пространство группы",
      "Кабинеты для индивидуальной психолого-педагогической работы",
      "Кабинеты для групповой психолого-педагогической работы",
      "Кабинеты для трудового обучения",
      "Физкультурный зал",
      "Бассейн",
      "Кабинеты ЛФК",
      "Кабинет логопеда",
      "Кабинет психолога",
      "Сенсорная комната",
      "Другое",
    ],
  },

  // СЕМЬЯ

  familyStatus: {
    selected: "",
    options: ["Полная", "Неполная", "Приемная", "Опекунская", "На попечении государства "],
  },
  childrenAmount: 1,
  workingFamilyMembers: 0,
  parentsEducation: {
    selected: "",
    options: ["Высшее", "Неоконченное высшее", "Среднее специальное", "Среднее", "Неполное среднее"],
  },
  livingConditions: {
    selected: "",
    options: [
      "Отдельная благоустроенная квартира/дом с удобствами",
      "Отдельная квартира/дом без удобств",
      "Более одной комнаты в коммунальной квартире с удобствами",
      "Комната в коммунальной квартире, общежитии с удобствами",
      "Комната в коммунальной квартире, общежитии, бараке без удобств",
    ],
  },
  financialSituation: {
    selected: "",
    options: [
      "Высокий материальный уровень, достаточные материальные возможности для социальной, медико-психологической реабилитации ребенка",
      "Средний материальный уровень, доступны платные медицинские услуги в интересах ребенка",
      "Ограниченные материальные возможности, ограниченная доступность обращения за платными медицинскими услугами для ребенка (только в случае необходимости)",
      "Низкие материальные возможности, имеется возможность получать медицинские услуги по основному заболеванию только в рамках ОМС",
    ],
  },
  recommendationsImplementation: {
    selected: "",
    options: ["Регулярное", "Периодическое", "Игнорирование"],
  },
  participationInEducation: {
    selected: "",
    options: ["Высокая", "Средняя", "Низкая"],
  },
  communicationPractice: {
    selected: "",
    options: ["Регулярная", "Нерегулярная", "Отсутствует"],
  },

  // ОРГАНИЗАЦИОННЫЙ РАЗДЕЛ
  periodGoal: "",
  educationGroupCondition: {
    selected: "",
    options: ["Однородная возрастная группа", "Смешанная возрастная группа"],
  },
  trainingMode: {
    selected: "",
    options: ["Группа кратковременного пребывания", "Ежедневное посещение", "Пятидневное пребывание", "Постоянно в интернате"],
  },
  trainingOrganiztion: {
    selected: "",
    options: ["Групповые занятия/ занятия в малой группе", "Индивидуальные занятия в организации", "Индивидуальные занятия на дому"],
  },

  characteristiсs: [
    {
      id: 1,
      title: "Характеристика социального-коммуникативного развития",
      setsToShow: [],
      currentSet: null,
      score: 0,
      done: false,
      sets: [
        {
          period: 1,
          score: 0,
          targets: [
            "•	плач, редкие звуки гуления, появление двигательного беспокойства, как средства информирования взрослого о своем физическом и психологическом состоянии; ",
            "•	спокойное состояние в ситуации комфорта; беспокойство, плач в ситуации дискомфорта; ",
            "•	ориентировка на свои физиологические ощущения: чувство голода/насыщения, опасность/безопасность;",
            "•	синхронизация эмоциональных реакций в процессе эмоционально-личностного общения с матерью, заражения улыбкой, согласованности в настроении и переживании происходящего вокруг;",
            "•	спокойное состояние на руках у матери, улыбка во время общения с ней.",
          ],
          content: [
            "формирование положительного отношения к разнообразным сенсорным ощущениям при выполнении взрослым гигиенических процедур и режимных моментов;",
            "поддержание социальных форм поведения при последовательной смене периодов сна и бодрствования,",
            "активизация поисковой пищевой реакции в процессе кормления;стимуляция эмоционального ответа при насыщении;",
            "формирования потребности во впечатлениях и активности путем кратковременного воздействия сенсорных стимулов на различные анализаторы; ",
            "формирование умения направлять голову и взгляд в сторону лица близкого взрослого при непосредственной тактильной стимуляции;",
            "формирование ответных эмоциональных реакций при контакте с матерью (ухаживающим взрослым) в различных ситуациях (гигиенические процедуры, кормление, общение, подготовка ко сну);",
            "изменение положения ребенка в пространстве для формирования привычки к переменам в окружающей среде;",
            "создание условий для формирования у ребенка ответных реакций на любое воздействие со стороны близкого взрослого; ",
            "стимуляция мимических проявлений и изменения поведения при ощущении комфорта и дискомфорта,",
            "продолжительное взаимодействие с близкими взрослыми; ",
            "формирование потребности в контакте с близким взрослым.",
          ],

          tasks: [
            {
              id: 1,
              target: "выявить изменение эмоционального состояния при прикосновениик телу.",
              material: "мягкая щеточка",
              description:
                "специалист располагает ребенка в удобной позе, привлекает его внимание речью ласковой интонации и в момент эмоционального общения поочередно слева и справа прикасается к шее или щеке ребенка мягкой щеточкой. При отсутствии реакции увеличивает площадь прикосновения, дует ребенку в лицо, поглаживает его ручки и тело.",
              options: [
                "на фоне эмоционального общения и поглаживания мягкой щеточкой шеи ребенка улыбка или мимика удовольствия не появляются, мимика не меняется",
                "на фоне эмоционального общения и поглаживания мягкой щеточкой шеи ребенка на его лице появляется улыбка или мимика удовольствия.",
              ],
              checked: false,
            },

            {
              id: 2,
              target: "выявить способность менять поведение при появлении матери (ухаживающего взрослого)",
              material: "не требуется.",
              description:
                "специалист просит мать или другого ухаживающего взрослого 2-3 минуты не появляться в поле зрения ребенка, а сам эмоционально общается с ним, вовлекает его в совместную деятельность, демонстрирует возможности разных игрушек. Спустя 2-3 минуты просит мать или другого ухаживающего взрослого подойти к ребенку, позвать его. Если поведение ребенка при появлении матери после ее отсутствия не меняется, педагог просит ее ласкового обратиться к нему, погладить, взять на руки",
              options: [
                "на лице ребенка во время общения с матерью или другим взрослым после ее (его) отсутствия в поле его зрения улыбка или мимика удовольствия не появляются.",
                "на лице ребенка во время общения с матерьюили другим взрослым после ее (его) отсутствия в поле его зрения появляется улыбка или мимика удовольствия.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить у ребенка ориентировку на ситуацию кормления, умение принимать пищу с помощью сосания.",
              material: "не требуется.",
              description: "специалист наблюдает за поведением ребенка непосредственно перед и во время кормления ребенка.",
              options: [
                "при наступлении времени кормления ребенок может оставаться пассивным или характерным поведением информирует взрослого о чувстве голода (беспокойство, плач с паузами), при попадании соска или соски в рот не может с помощью ритмичного сосания съесть весь объем пищи, во время кормления нужно делать паузы, прием пищи осуществляется длительно и с трудом.",
                "При наступлении времени кормления ребенок характерным поведением информирует взрослого о чувстве голода (беспокойство, плач с паузами), при попадании соска или соски в рот начинает активно сосать и постепенно, с помощью ритмичного состояния, съедает весь объем жидкой пищи, не пропихиваясь.",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 2,
          score: 0,
          targets: [
            "•	информирование взрослого о дискомфорте/комфорте, после выполнения акта дефекации/мочеиспускания изменением мимики и поведения;",
            "•	стойкий интерес к внешним сенсорным стимулам, происходящему вокруг, длительное рассматривание предметов, тактильное обследование предметов, поверхностей;",
            "•	поддержание положительного эмоционального контакта в процессе общения со взрослыми, отражение эмоционального состояния взрослого;",
            "•	дифференцированные мимические проявления при общении со взрослыми и в процессе действий с игрушками; ",
            "•  дифференциация внешних воздействий (приятно/неприятно), использование мимики и движений с целью коммуникации (удовлетворение/неудовлетворение);",
            "•  реакция предвосхищения и прогнозирование поведения взрослого в знакомых ситуациях (кормление, купание);",
          ],
          content: [
            "формирование умения согласовывать движения рук, удерживать предмет двумя руками, использовать движения мимики в качестве социального способа коммуникации (при приеме пищи мимикой и поведением информировать взрослого о чувстве голода и насыщении, нежелании принимать пищу);",
            "создание условий для овладения умением снимать губами пищу с ложки, обучение захватыванию рукой кусочков пищи рукой, умения направлять в рот, откусывать, жевать и глотать пищу мягкой текстуры;",
            "формирование умения открывать и закрывать рот, по просьбе взрослого, пить из чашки, удерживая ее двумя руками при постоянной помощи взрослого; ",
            "поддержание устойчивого интереса к окружающим сенсорным стимулам, предметам среды и происходящему вокруг;",
            "формирование умения исследовать близко расположенное пространство с помощью исследовательских/ощупывающих движений рук, согласовывая их между собой, а также с помощью зрения (при наличии зрительного восприятия);",
            "подкрепление положительного эмоционального ответа в виде обмена взглядами, направления рук в сторону взрослого при его появлении, улыбки и звуков лепета во время эмоционального общения с ним;",
            "формирование дифференцированных способов информирования взрослого при возникновении чувства удовольствия и неудовольствия, в том числе при возникновении желания до или при появлении неприятных ощущений после акта дефекации/мочеиспускания; ",
            "создание условий для возникновения у ребенка ощущения психологического комфорта, безопасности в новом пространстве как основы для его изучения совместно со взрослым;",
            "формирование интереса к совместным действиям с новым взрослым (педагогом) в процессе осуществления режимных моментов, бытовых и игровых ситуаций;",
            "формирование умения реагировать на свое имя; ",
            "создание условий для регулярного использования для общения невербальных средств (жестов, совместно-разделенной деятельности, системы альтернативной коммуникации «календарь», предметно-игрового взаимодействия);",
            "формирование навыков социального поведения: умения выполнять элементарные действия в процессе выполнения режимных моментов;",
            "увеличение времени активного бодрствования за счет двигательной и познавательной активности, самостоятельного выполнения предметных действий;",
            "формирование социальных способов эмоционально-положительного общения с матерью (ухаживающим взрослым);",
            "увеличение продолжительности и расширение социальных способов зрительного и тактильного взаимодействия с близкими людьми, в том числе указательного жеста рукой.",
          ],
          tasks: [
            {
              id: 1,
              target:
                "Выявить наличие различных (дифференцированных) эмоциональных ответов и поведения на появление в поле зрения знакомых и незнакомых людей.",
              material: "не требуется.",
              description:
                "педагог располагает ребенка в удобной позе в безопасности и поочередно просит мать/ухаживающего взрослого удалиться из помещения на 2-3 минуты, а потом вернуться. Спустя 3-5 минут просит зайти незнакомого ребенку человека и привлечь к себе внимание ребенка речевым обращением (ласковой интонацией). Если обычное поведение ребенка при появлении матери/ухаживающего взрослого и незнакомого взрослого не меняется, их просят поговорить с ребенком, погладить его, взять на руки.",
              options: [
                "При появлении матери/ухаживающего взрослого в поле зрения ребенка поведение и мимика не изменяются, как и при появлении незнакомого человека.",
                "При появлении матери/ухаживающего взрослого в поле зрения ребенка на его лице появляется улыбка, он тянет к матери/ухаживающему взрослому руки, может произнести отдельные речевые звуки или слоги лепета. При появлении незнакомого человека на лице ребенка появляется мимика удивления или недовольства, страха.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить умение глотать полугустую пищу.",
              material: "полугустая пища из рациона питания ребенка.",
              description:
                "специалист просит родителей/ухаживающих взрослых принести с собой на психолого-педагогическую консультацию пюре или творожок из рациона питания ребенка и покормить по завершению процедуры обследования.",
              options: [
                "ребенок принимает пищу в позе сидя, не умеет снимать полугустую пищу губами с ложки, глотает ее с трудом, поперхивается, ложку рукой не хватает, к кормлению полугустой пищей относится негативно;",
                "ребенок принимает пищу в позе сидя, снимает губами пищу с ложки, без труда глотает ее; хватает ложку с едой, облизывает ложку, сам удерживая ее рукой.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить способность инициировать общение с помощью движений рук и поведения.",
              material: "яркая светящаяся игрушка.",
              description:
                "специалист оставляет ребенка в удобной позе в безопасности, а сам отходит на некоторое расстояние от него, после чего привлекает внимание к яркой светящейся игрушке и наблюдает за поведением ребенка. Если поведение ребенка не меняется, то он дает ребенку игрушку, берет руки ребенка своими руками и извлекает из игрушки звук, после чего отходит от ребенка и наблюдает за его поведением.",
              options: [
                "поведение ребенка не меняется, взаимодействия со взрослым с помощью направленных движений рук, взгляда, речевых звуков не инициирует.",
                "ребенок либо прекращает движение, либо становится беспокойным, смотрит на игрушку, тянет в ее сторону руки, инициирует общение и взаимодействие со взрослым взглядом, речевыми звуками и лепетом.",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 3,
          score: 0,
          targets: [
            "• правильно использует орудия: чашка, ложка при приеме пищи;",
            "• о физиологических потребностях сообщает взрослому заранее, использует социальные жесты, речевые средства (слог, слово), меняет поведение, стремится быть опрятным;",
            "• ориентируется на эмоциональное состояние взрослого;",
            "• выражает свое настроение и желания с помощью различных мимических средств, социальных жестов и речи в процессе предметно-практической деятельности.",
          ],
          content: [
            "поддержание интереса ребенка к взаимодействию с взрослым в процессе эмоционального общения,осуществлениярежимных моментов, игровой ситуации и совместных предметно-игровых действий;",
            "формирование умения удерживать в руке ложку, совершать черпающее движение, подносить ко рту, снимать пищу губами, хорошо пережевывать мягкие продукты;",
            "формирование умения удерживать в руках чашку, изменять наклон, пить из нее, делать глоток;",
            "развитие навыка социальной коммуникации иинформирования о желании в туалет с помощью изменения поведения, социального жеста, слога или облегченного слова;",
            "совершенствование точности и координации движений рук и пальцев при выполнении действий с полотенцем, расческой, ложкой, чашкой и др.;",
            "увеличение продолжительности сотрудничества и навыка подражания действиям взрослого с предметами; ",
            "обучение выполнению цепочки последовательных действий с предметами по подражанию; ",
            "формирование умения откликаться на свое имя, радоваться похвале и огорчаться запрету;",
            "формирование понимания значения социального жеста, показанного взрослым и его соотнесения со словом, переход к вербально-жестовой форме общения;",
            "развитие умения менять свое поведение по требованию взрослого и согласовывать свои действия с его действиями;",
            "формирование указательного жеста, указание на себя рукой как предпосылка осознания себя; ",
            "формирование социального поведения при выполнении режимных моментов: помощь в выполнении действий и поддержание проявлений самостоятельности; ",
            "формирование умения демонстрировать свое отношение к происходящему изменением поведения, мимикой, интонацией и социальными жестами;",
            "развитие ориентировки и узнавания своих вещей и близких людей за счет расширения объема восприятия и памяти;",
            "обучение согласованию эмоционального состояния с эмоциональным состоянием взрослого, отражение его путем изменения поведения и мимики, выражение привязанности и любви социальными способами;",
            "формирование навыков коммуникации с взрослым и информирования о своих желаниях жестово-вербальными средствами;",
            "поддержка интереса к совместным действиям со сверстником в ситуации, организованной взрослым (зрительное внимание, направленное на сверстника, положительное эмоциональное отношение к нему, инициативные действия);",
            "обучение ориентировке в окружающем за счет анализа ощущений, полученных с различных анализаторов, в том числе, с поверхности руки и кончиков пальцев;",
            "обучение ориентировке в собственном теле и лице взрослого за счет осуществления исследовательских движений рук, в том числе умение находить определенную часть тела/лица на себе, близком, игрушке; ",
            "стимуляция появления чувства удовлетворения при достижении ожидаемого результата, похвале со стороны взрослого.",
          ],
          tasks: [
            {
              id: 1,
              target: "выявить умение выбирать моторный акт, способ приема пищи в зависимости от ее текстуры.",
              material: "твёрдая и жидкая пища.",
              description:
                "специалист просит родителей/ухаживающего взрослого принести с собой на консультацию пюре, творожок и кусочек яблока, сухарик из рациона питания ребенка и покормить по завершению процедуры обследования.",
              options: [
                "не может самостоятельно подобрать результативное движение для приема полугустой и твёрдой пищи, не зачерпывает ложкой полугустую пищу и не направляет ложку в рот; не жует, не глотает твёрдые кусочки, поперхивается, удерживает сухарик/яблоко в руке;",
                "совершает результативное движение рукой (снимает полугустую пищу губами с ложки и глотает, откусывает кусочек яблока или сухарика и жует), зачерпывает ложкой и направляет ложку в рот, самостоятельно съедает небольшой объем пищи.",
              ],
              checked: false,
            },
            {
              id: 2,
              target:
                "выявить способность инициировать общениесо взрослым с помощью социального жеста (указательный жест, «дай»), прикосновением, похлопыванием привлекать внимание.",
              material: "яркая игрушка с сюрпризным эффектом.",
              description:
                "специалист во время эмоционально-развивающего общения оставляет ребенка в игровом пространстве одного в безопасности и наблюдает за его деятельностью, ожидая с его стороны инициативы общения и фиксируя внимание на способе, которым он пользуется для этого. Если ребенок не инициирует общения, специалист привлекает его внимание к яркой игрушке с сюрпризным элементом, либо к его любимой игрушке, с которой он пришел и наблюдает за его поведением.",
              options: [
                "не использует социальный жест (указательный жест, «дай»), прикосновения, похлопывания для привлечения внимания взрослого, не инициирует общение;",
                "привлекает внимание взрослого и инициирует общение с помощью социального жеста (указательный жест, «дай»), прикосновением, похлопыванием.",
              ],
              checked: false,
            },
            {
              id: 3,
              target:
                "выявить способность сотрудничества со взрослым в виде повторения (копирования знакомого действия, движения) действий и элементы самостоятельности при одевании.",
              material: "детский ботиночек или шапка.",
              description:
                "специалист во время эмоционально-развивающего общения показывает ребенку,как снять ботинок с ноги или шапку с головы и просит его повторить действие. Если ребенок не делает этого после показа, специалист выполняет действие «рука в руку» с ребенком, после чего просит его повторить его самостоятельно.",
              options: [
                "не совершает попыток снятия одежды (ботинка с ноги, шапочки с головы), не согласует движения рук между собой, не повторяет действий вслед за взрослым.",
                "самостоятельно стягивает ботинок с ноги, а шапочку с головы, совершает попытки одеть их, действует двумя руками.",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 4,
          score: 0,
          targets: [
            "•  наличие самостоятельности во время приема пищи, при выполнении акта дефекации/мочеиспускания, гигиенических процедур, одевании;",
            "•  информирование взрослого о своих чувствах и потребностях с помощью речи;",
            "•  социальное поведение в знакомой и незнакомой ситуации.",
          ],

          content: [
            "совершенствование навыка использования средств социальной коммуникации (мимики, жестов, речи)со взрослыми и детьми;",
            "обеспечение определенной степени самостоятельности при выполнении знакомой деятельности и ориентировки в окружающем;",
            "совершенствование навыка приема пищи за столом с помощью различных столовых приборов (вилкой, ложкой);",
            "формирование умения пользоваться салфеткой, есть аккуратно;",
            "развитие самостоятельности во время выполнения гигиенических процедур; при выполнении акта дефекации/мочеиспускания;",
            "развитие навыков одевания – раздевания;",
            "формирование навыков опрятности;",
            "закрепление привычки придерживаться социальных норм поведения;",
            "развитие умения сообщать о своих желаниях, самочувствии и эмоциональном состоянии (радость, грусть, обида, удивление) с помощью речи;",
            "обучение воспроизведению предметно-игровых действий по подражанию и показу;",
            "формирование представлений о себе;",
            "развитиепроизвольности поведения и умения придерживаться социальных правил: ориентироваться на требования взрослого, вести себя спокойно, включаться в занятие, спать в кроватке, убирать игрушки в емкость.",
          ],
          tasks: [
            {
              id: 1,
              target: "выявить наличие умения самостоятельно принимать пищу с помощью орудия (ложки).",
              material: "безопасная детская посуда.",
              description:
                "специалист создает ситуацию кормления и на фоне эмоционально-положительного контакта с ребенком привлекает его внимание к еде, ложке. Берет руку ребенка своей рукой, действует «рука в руку»: находит и ощупывает тарелку, ложку, зачерпывает еду и помогает направить ложку с едой в рот, делает так 1-2 раза. После чего помощь не оказывает, наблюдает за действиями ребенка.",
              options: [
                "не умеет принимать пищу с помощью орудия (ложки), не съедает порцию еды сам (в этом случае программа результат не сохраняет, поле остается не заполненным).",
                "самостоятельно принимает пищу с помощью орудия (ложки), съедает порцию еды сам (данная формулировка фиксируется в документе без указания балла). ",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить умение надевать и снимать одежду (куртку, обувь, брюки).",
              material: "не требуется.",
              description:
                "специалист просит ребенка с помощью простой фразы или показа снять/надеть куртку, обувь, брюки. Допускается однократная демонстрация верного образца деятельности. Взрослый, действуя «рука в руку», последовательно выполняет каждое движение, сопровождая его речевым комментарием: «Беру. Тяну. Вот так. Снял. Всё. Молодец». После чего просит ребенка выполнить просьбу самостоятельно.",
              options: [
                "не понимает и не выполняет просьбу, самостоятельно не надевает и не снимает одежду (куртку, обувь, брюки);",
                "понимает и выполняет просьбу, самостоятельно надевает и снимает одежу (куртку, обувь, брюки).",
              ],
              checked: false,
            },
            {
              id: 3,
              target:
                "выявить умение использовать орудия труда в быту (вытирать стол тряпкой, мести веником, вешать ткань на веревку и закреплять ее прищепками).",
              material: "ткань, веник, верёвка и прищепки.",
              description:
                "специалист просит ребенка вербально или дактильно вытереть стол, подмести пол веником, повесить тряпку на верёвку и закрепить её прищепками. Использует простую фразу: «Подмети веником пол. Вытри тряпкой стол. Повесь тряпку на верёвку. Закрепи тряпку прищепкой. Прикрепи здесь прищепку». Допускается однократная демонстрация верного образца деятельности. Взрослый, действуя «рука в руку», последовательно выполняет каждое движение, сопровождая его речевым комментарием. После чего просит ребенка выполнить просьбу самостоятельно.",
              options: [
                "не понимает и не выполняет просьбу самостоятельно, не умеет использовать орудия труда в быту.",
                "понимает и выполняет просьбу самостоятельно, умеет использовать орудия труда в быту.",
              ],
              checked: false,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      title: "Характеристика физического развития",
      setsToShow: [],
      currentSet: null,
      score: 0,
      done: false,
      sets: [
        {
          period: 1,
          score: 0,
          targets: [
            "•	спокойное состояние или положительное отношение к изменению позы, участию в гимнастике, внешней активизации движений;",
            "•	умение принять удобное положение, изменить позу на руках у матери и поменять позу лежа на спине, животе на твердой горизонтальной поверхности для изучения окружающей среды, рассматривания пространства;",
            "•	рреакция сосредоточения при воздействии сенсорных стимулов обычной интенсивности на нормально функционирующие анализаторы; воздействие стимулов высокой или средней интенсивности на анализаторы со снижением функциональных возможностей;",
            "•	поиск сенсорного стимула за счет движений головы, поисковых движений глаз, поисковые движения руки, локализация положения или зоны его воздействия;",
            "•	поисковые движения головой и телом, поворот на бок, живот.",
          ],
          content: [
            "формирование потребности в двигательной активности; ",
            "фформирование умения удерживать голову в различных позах, в том числе, положении на животе; осуществлять контроль равновесия тела при опоре на предплечья;",
            "формирование навыка группирования при изменении положения тела в пространстве; ",
            "стимуляция к изменению положения при поиске сенсорного стимула;",
            "формирование умения осуществлять ритмичные движения артикуляционного аппарата при кормлении;",
            "развитие содружественных движений рук с целью познания близкого пространства и предметов внешнего мира;",
            "развивать умение совершать изолированные движения пальцами, ощупывающие движения ладоней рук и пальцев;",
            "формировать потребность поиска игрушки движениями рук;",
            "развитие умения совершать изолированные движения пальцами, формирование потребности в поиске игрушки, ощупывающих движений ладоней рук и пальцев.",
          ],
          tasks: [
            {
              id: 1,
              target: "выявить способность группировки и принятия удобной позы на руках у взрослого.",
              material: "не требуется",
              description:
                "специалист во время эмоционально-положительного общения с ребенком берет его на руки, качает его и демонстрирует игрушку, спустя минуту передает его обратно в руки родителя.",
              options: [
                "при взятии на руки взрослым ребенок не группируется, не принимает удобную позу, не удерживает голову.",
                "при взятии на руки взрослым ребенок группируется, принимает удобную позу, удерживает голову.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить умение удерживать игрушку в руке, когда ее туда вложили.",
              material: "легкая маленькая яркая погремушка, которая издает звук при малейшем движении ею.",
              description:
                "специалист во время эмоционально-положительного общения, совершает своей рукой поглаживающие движения по руке ребенка, аккуратно раскрывает кулачок и вкладывает в руку ребенка погремушку.",
              options: [
                "не сжимает кулачок, не захватывает погремушку, не двигает рукой.",
                "сжимает кулачок, захватывает погремушку, непроизвольно совершает движение рукой, извлекает звук, на лице появляется мимика удовольствия.",
              ],
              checked: false,
            },
            {
              id: 3,
              target:
                "выявить умение изменять положение тела путем поворота на бочок для ориентировки в пространстве или поиска источника звука, внешнего стимула.",
              material: "игрушка с громким звуком.",
              description:
                "специалист оставляет ребенка в удобной позе в безопасном месте и отходит на небольшое расстоянии, извлекает из игрушки звук несколько раз. Допускается однократная помощь ребенку. Взрослый поднимает края пеленки, на которой лежит ребенок, и помогает ему совершить поворот на бок, живот и обратно. После чего ждет, когда ребенок выполнит движение самостоятельно.",
              options: [
                "не поворачивается на бок и обратно, не совершает поисковых движений головой. ",
                "поворачивается на бок и обратно, совершает поисковые движения головой, пытается найти взглядом источник звука.",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 2,
          score: 0,
          targets: [
            "•	передвижение в пространстве с помощью сложных координированных моторных актов, повороты со спины на живот, ползание.",
            "•  появление предпочитаемых статических поз как свидетельство наличия устойчивых, длительных положительных эмоциональных реакций;",
            "•  захват, удержание предметов руками, выполнение манипулятивных действий с игрушкой.",
          ],
          content: [
            "развитие равновесия и навыка контроля положения тела в различных позах: на руках у взрослого в вертикальной позе, на животе, в позе полусидя, стоя на коленях с поддержкой подмышки;",
            "формирование умения самостоятельно осуществлять вестибулярный контроль положения тела с учетом внешних условий и ситуации (удобная поза во время кормления, при игре с игрушками);",
            "формирование навыка группировки и изменения положения тела в пространстве, самостоятельный переход из положения на спине в положение на животе;",
            "сохранение равновесия в вертикальном положении на руках взрослого, с опорой корпуса на его плечо;",
            "формирование согласованных движений рук, закрепление умения захватывать предметы, удерживать их, противопоставлять большой палец всем остальным, осуществлять исследовательские движения пальцами рук, выполнять различные по сложности социальные действия с игрушками (манипуляторные, специфические и орудийные);",
            "создание условий для перекладывания игрушек из одной руки в другую, увеличение зрительного или перцептивного контроля;",
            "формирование умения сохранять позу сидя с опорой на руку или спинку стула;",
            "при отсутствии выраженных двигательных нарушений формирование навыка передвижения в пространстве, т.е. овладение ползанием (развитие координированного взаимодействия в движениях рук и ног), умением сидеть;",
            "при отсутствии выраженных двигательных нарушений формирование навыка сохранения равновесия при перемещении в пространстве и выполнении различной цепочки движений, приподнимание корпуса тела стоя у опоры с кратковременным сохранением равновесия в вертикальном положении, стоя на коленях или на ногах, переход из позы стоя в позу сидя, лежа, в том числе группирования при падении;",
            "создание условий для укрепления мышц ног и рук; закрепление навыка опоры на ножки, отталкивания руками (катание на большом мяче), опоры на руки для удержания позы тела.",
          ],
          tasks: [
            {
              id: 1,
              target: "определить умение совершать координированные движения для приближения к интересующему предмету.",
              material: "яркая игрушка со светящимся элементом.",
              description:
                "ребенок располагается в позе на спине на твердой поверхности (манеж, коврик). В непосредственной близости на расстоянии вытянутой руки ребенка находится яркая игрушка. Взрослый привлекает внимание ребенка к игрушке и следит за его поведением. Можно прикоснуться игрушкой к руке ребенка, оказать ему небольшую помощь в виде поддержки для выполнения движения и перемещения тела вперед.",
              options: [
                "не меняет положения тела (не переворачивается на живот),не встает на четвереньки, не совершает раскачивания корпусом.",
                "произвольно меняет положение тела (переворачивается на живот), встает на четвереньки, совершает раскачивание корпусом, пытается дотянуться до игрушки, отрывает одну руку от поверхности, протягивает ее к предмету, производит попытки переместиться вперед по типу ползания.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить умение выполнять простые действия с игрушкой.",
              material: "погремушка с длинной ручкой (10-12 см) со светящимся элементом.",
              description:
                "взрослый располагает ребенка в позе на спине и привлекает его внимание к игрушке, расположенной на высоте 20-30 см. над грудью, прикасается игрушкой к пальцам руки ребенка и опять поднимает ее вверх, располагая над грудью на уровне его глаз. Можно вложить ребенку предмет в руку и выполнить размахивающее движение или другую манипуляцию с ней.",
              options: [
                "не захватывает игрушку, из вложенной в руку звук извлечь не может, не размахивает.",
                "направляет руки к игрушке, берет ее, захватывает, совершает размахивающие движения рукой, перекладывает из руки в руку, сжимает, бросает.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить умение удерживать во время кормления бутылочку с едой двумя руками ",
              material: "детская бутылочка с едой",
              description: "взрослый во время кормления располагает руки ребенка на детской бутылочке с едой и следит за его поведением.",
              options: [
                "не захватывает бутылочку (опускает руки) и не удерживает ее некоторое время.",
                "захватывает бутылочку и удерживает ее некоторое время, пытается направить в рот.",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 3,
          score: 0,
          targets: [
            "•	контроль положения тела в позе сидя, стоя на коленях, ходьбы у опоры (на небольшие расстояния);",
            "•	выполнение по памяти последовательности социальных действий при одевании, кормлении;",
            "•	согласование движений с движениями взрослого, предвосхищение действий и преднамеренное выполнение 1-2 действий в цепочке;",
            "•	длительное продуктивное взаимодействие в удобной физиологически правильной позе.   время вертикализации с поддержкой.",
          ],
          content: [
            "развитие навыка контроля положения тела и удержания в статическом положении, при передвижении и во время выполнения игровых действий;",
            "развитие физической силы (выносливости) и точных движений во время продуктивной и игровой деятельности;",
            "создание условий для совершенствования навыка самостоятельной ходьбы: изменения направления, скорости, преодоления препятствий;",
            "формирование навыка использования физических и двигательных возможностей для влияния на ситуацию, при выполнении действий с предметами, в том числе, в ходе продуктивной и игровой деятельности;",
            "умение самостоятельно выбирать результативное движение для выполнения предметного, орудийного, игрового действия/ деятельности, влияния и изменения ситуации, достижения намеченной цели;",
            "формирование навыка выполнения содружественных и изолированных ритмичных движений (ногами, руками, головой, телом), согласования движений с движениями взрослого и музыкальным ритмом;",
            "умение придавать руке удобное положение для выполнения социальных действий с предметами и орудиями в ходе предметной, игровой, продуктивной деятельности;",
            "подражать цепочке движений, которую совершает взрослый;",
            "формирование умения действовать двумя руками, одной рукой, выполнять точные координированные движения пальцами рук, выбирать удобное положение руки для орудийного и предметного действия, продуктивной и игровой деятельности;",
            "формирование умения использовать свои перцептивные ощущения для ориентировки в пространстве во время передвижения, контроля и оценки результативности действия.",
          ],
          tasks: [
            {
              id: 1,
              target: "выявить умение передвигаться при помощи самостоятельной ходьбы, осуществлять повороты, управлять своим телом.",
              material: "игрушка (мишка, кукла).",
              description:
                "взрослый располагает ребенка на твёрдой поверхности рядом с опорой и привлекает его внимание к игрушке, постепенно отходитс ней от ребенка на небольшое расстояние 0,5 м. и наблюдает за его поведением. Можно оказать помощь и помочь ребенку принять позу стоя у опоры.",
              options: [
                "не может самостоятельно встать с опоры, сделать 1-2 шага в сторону взрослого.",
                "ребенок встает у опоры и делает 1-2 шага в сторону взрослого.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить понимание, что с помощью моторного акта можно привести в движение качели.",
              material: "игрушка-качалка, которая приходит в движения за счет совершения наклонов тела.",
              description:
                "взрослый сажает ребенка на игрушку-качалку и демонстрирует ему движение, с помощью которого игрушка начинает качаться (наклоняет корпус ребенка вперед, затем отклоняет назад). После чего наблюдает за поведением ребенка.",
              options: [
                "не приводит игрушку-качалку в движение, не удерживает равновесие.",
                "совершает результативное движение телом, приводит игрушку-качалку в движение, удерживает равновесие.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить умение подражать движению взрослого.",
              material: "не требуется.",
              description:
                "во время эмоционально-положительного общения взрослый хвалит ребенка, гладить по голове и начинает хлопать в ладоши, однократно выполняет хлопок руками ребенка, затем продолжает хлопать сами и наблюдает за поведением ребенка.",
              options: ["ребенок не подражает действию взрослого, не хлопает в ладоши;", "ребенок подражает действию взрослого, хлопает в ладоши."],
              checked: false,
            },
          ],
        },
        {
          period: 4,
          score: 0,
          targets: [
            "• разрешение проблемной ситуации и преодоление препятствий, игнорирование лишних предметов при выполнении задания;",
            "• длительное удержание правильной позы в положении стоя, сидя за столом и на полу при выполнении игровых действий;",
            "• выполнение простой схемы движений по инструкции;",
            "• выполнение изолированных движений кистями и пальцами рук.",
            "• согласование движений с музыкальным ритмом, образцом взрослого, выполнение изолированных движений кистями и пальцами рук.",
          ],
          content: [
            "развитие навыка подражания простой схеме движений вслед за взрослым;",
            "формирование интереса к выполнению разных физических упражнений, потребности в разных видах двигательной деятельности;",
            "закрепление навыка и формирование привычки удерживать правильную позу и положение руки при обследовании предметов и ориентировке в пространстве; ",
            "развитие умения выполнять движения по инструкции;",
            "отработка техники ходьбы: правильной постановки стоп, положения тела, координации движений рук и ног при ходьбе;",
            "развитие умения согласовывать темп ходьбы со звуковым сигналом, музыкальным ритмом;",
            "формирование умения произвольно менять скорость и направление движения;",
            "совершенствование координации и качества движений при самостоятельном спуске и подъёме по лестнице, умения держаться за перила, поочередно переступать ногами, в том числе, по поверхности с разным наклоном;",
            "развитие умения выполнять по инструкции ряд последовательных движений без предметов и с предметами;",
            "развитие навыка выполнения координированных движений руками при игре с мячом разного размера в соответствии с созданной взрослым ситуацией: бросать мяч одной рукой или двумя, рассчитывать силу броска, толкать от себя ногой или руками.",
          ],
          tasks: [
            {
              id: 1,
              target: "выявить умение осуществлять ориентировку в ситуации, прогнозировать события, управляя движениями избегать опасности.",
              material: "порожек, блок конструктора (3-5 см. в высоту и 50 см. в длину).",
              description:
                "специалист во время эмоционально-положительного общения просит ребенка (речью или жестом) перешагнуть через препятствие. Помогает ему осуществитьориентировку в пространстве, найти порожек, препятствие. Разрешается однократно продемонстрировать верный образец деятельности. Оказать помощь при первом выполнении. Затем предложить выполнить деятельность самостоятельно. Дети с ограничением движений могут пользоваться опорой.",
              options: [
                "не умеет осуществлять ориентировку в ситуации, прогнозировать события и не управляет движениями, не может избежать опасности, перешагнуть через препятствие.",
                "умеет осуществлять ориентировку в ситуации, прогнозировать события, с помощью управления движениями избегает опасности, перешагивает через препятствие.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить умение подражать социальным движениям взрослого, после их наглядной или практической демонстрации.",
              material: "не требуется.",
              description:
                "специалист во время эмоционально-положительного общения демонстрирует ребенку социальный жест «говорю по телефону», подносит руку к уху, изображая разговор по телефону. Объясняет его значение вербально: «Я как - будтоговорю по телефону. А ты сделай так, как я» Покажи, как ты говоришь по телефону. Как рисуешь». Разрешается однократно продемонстрировать верный образец деятельности. Оказать помощь при первом выполнении. Затем предложить выполнить деятельность самостоятельно.",
              options: [
                "не подражает социальным движениям взрослого после их наглядной или практической демонстрации;",
                "подражает социальным движениям взрослогопосле их наглядной или практической демонстрации.",
              ],
              checked: false,
            },
            {
              id: 3,
              target:
                "выявить умение выполнять точные координированные моторные акты, согласуя движения рук между собой, ориентируясь и анализируя перцептивные ощущения, полученные с поверхности пальцев рук, подражать деятельности взрослого, воссоздавать сложную конструкцию, ориентируясь на образец.",
              material: "крупная мозаика.",
              description:
                "в ситуации эмоционально-положительного общения специалист просит ребенка (вербально или жестами) выстроить в определённой последовательности несколько крупных деталей мозаики на игровом поле (солнышко, цветок). Разрешается однократно продемонстрировать верный образец деятельности. Выполнить последовательно результативную цепочку действий совместно с ребенком, «рука в руку». Затем предложить повторить деятельность самостоятельно.",
              options: [
                "не выполняет точные координированные моторные акты, согласуя движения рук между собой, не анализирует и не ориентируется на перцептивные ощущения, полученные с поверхности пальцев рук, не может вставить детали мозаики в игровое полотно, не подражает деятельности взрослого;",
                "выполняет точные координированные моторные акты, согласуя движения рук между собой, анализируя и ориентируясь на перцептивные ощущения, полученные с поверхности пальцев рук, вставляет детали мозаики в игровое полотно, подражает деятельности взрослого.",
              ],
              checked: false,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      title: "Характеристика познавательного развития",
      setsToShow: [],
      currentSet: null,
      score: 0,
      done: false,
      sets: [
        {
          period: 1,
          score: 0,
          targets: [
            "•	ориентировочная и поисковая активность во время бодрствования; ",
            "•	развитие зрительного сосредоточения на предмете, проявление интереса к нему и положительная реакция на новизну;",
            "•	поисковые и исследовательские движения взгляда при перемещении лица взрослого в поле зрения или ярких крупных предметов;",
            "•	активное использование осязательного восприятия при ориентировке на вкусовые свойства пищи, появление предпосылок дифференцировки «приятно-неприятно»;",
            "•	поисковая реакция на громкий звук, на громкую речь, голос;",
            "•	улыбка и активизация движений при воздействии знакомых сенсорных стимулов;",
            "•	спонтанное наталкивание на предмет за счет выполнения движений руками;",
            "•	захват вложенной в руку игрушки, размахивающие движения рукой с целью извлечения звука, направление руки с игрушкой в сторону рта, обследование губами и языком;",
            "•	соединение рук перед собой, направление руки к предмету и осуществление захвата, ощупывание;",
            "•	инициация общения повышением двигательной активности, поисковыми движениями головы, изменением мимики, звуками гуления требовательной интонации.",
          ],
          content: [
            "формирование поискового поведения при воздействии сенсорных стимулов высокой и средней интенсивности, во время контакта с внешней средой;",
            "стимуляция к поиску сенсорного стимула за счет выполнения движений головы, поисковых движений глаз, поисковых движений руки, локализации зоны воздействия;",
            "создание условий для развития зрительных реакций: фиксация взгляда, длительное прослеживание взором за двигающейся игрушкой в различных направлениях, рассматривание или изучение предметов взглядом;",
            "накопление опыта положительного взаимодействия со взрослыми во время общения и совместных действий с игрушками;",
            "формирование навыка направления головы и взгляда в сторону тактильных или вибрационных ощущений при внешнем воздействии на определенную зону тела;",
            "развитие согласованных движений глаз при исследовании движущегося предмета;",
            "стимулирование захвата предметов рукой с поворотом головы и направление взгляда в место их расположения;",
            "формирование интереса к звукам разной громкости, к громкому голосу взрослого с постепенным удалением источника от уха;",
            "формирование реакций сосредоточения в момент случайного извлечения ребенком звука игрушки (находящейся в непосредственной близости от ребенка)",
            "развитие слуховых ориентировочных реакций на разные акустические стимулы;",
            "стимуляция эмоциональных реакций в виде изменения поведения и двигательной активности при восприятии знакомых звуков доступной громкости;",
            "создание условий для возникновения различных психологических ответов реагирования на воздействие тактильных или вибрационных стимулов,",
            "формирование захвата вложенной в руку игрушки, выполнения движений рукой с целью извлечения звука, ощупывание как исследование ее свойств;",
            "развитие умения ощупывать пальцами предмет, вложенный в руку взрослым, затем самостоятельно захватывать и удерживать как основы осязания;",
            "формирование навыка изменения двигательной активности в ответ на внешнее воздействие;",
            "стимулирование появления согласованных двигательно-эмоциональных ответов при возникновении знакомой ситуации и внешнем воздействии; ",
            "формирование интереса и социальных ответов на воздействие различных сенсорных стимулов.",
          ],
          tasks: [
            {
              id: 1,
              target:
                "выявить способ реагирования на воздействие зрительного стимула (фиксация взгляда, длительное прослеживание взором за двигающейся игрушкой в различных направлениях).",
              material: "крупная яркая контрастная по цветовой гамме, звучащая игрушка.",
              description:
                "специалист кладет ребенка спиной на пеленальный стол, на расстоянии 30–50 см от глаз ребенка располагает контрастную игрушку, медленно перемещает ее перед глазами ребенка, ловит его взгляд, добившись сосредоточения взгляда, отводит игрушку в сторону (влево, вправо, вверх, вниз).",
              options: [
                "отсутствие фиксации взгляда на игрушке.",
                "фиксация взгляда на предмете длительная, совершает плавное прослеживание глазами вслед за двигающейся игрушкой в разных направлениях.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить способности к поиску акустического сенсорного стимула за счет выполнения движений головы, поисковых движений глаз.",
              material: "яркая контрастная игрушка с громким звуком (бубен, клаксон).",
              description:
                "специалист кладет ребенка спиной на пеленальный стол и располагает то с одной, то с другой стороны от ребенка на расстоянии 30-50 см. от уха звучащую игрушку,ждет, когда ребенок примет удобную позу и извлекает из игрушки звук, наблюдает за реакцией ребенка.",
              options: [
                "замирает при звучании игрушки, не совершает поисковых движений головой и глазами, не поворачивает в сторону звучащего предмета голову.",
                "замирает при звучании игрушки, совершает поисковые движения головой и глазами, поворачивает в сторону звучащего предмета голову.",
              ],
              checked: false,
            },
            {
              id: 3,
              target:
                "выявить наличие потребности во впечатлениях, в раздражителях (беспокойство при отсутствии впечатлений или улыбка при воздействии сенсорного стимула).",
              material:
                "контрастная звенящая подвеска с игрушками, расположенная на расстоянии вытянутой детской руки сбоку от ребенка, прикрепленная к решетке кровати.",
              description:
                "специалист кладет ребенка на спину в кроватку, располагает над ним подвеску и оставляет его в тишине одного, отходит в сторону и наблюдает за поведением ребенка.",
              options: [
                "поведение не меняется, не совершает движений, случайно не дотрагивается до подвески, не извлекает звук.",
                "спустя некоторое время проявляет беспокойство, совершает движения, случайно извлекает звук из подвески, на лице появляется мимика удовольствия, повторят результативное движение несколько раз.",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 2,
          score: 0,
          targets: [
            "• зрительная ориентировка на внешний вид предмета;",
            "• опознание сенсорных стимулов и объектов;",
            "• проявление эмоционального положительного отклика на эмоциональные игры со взрослым;",
            "• выполнение сложных координированных моторных актов руками – специфические манипуляции со знакомыми игрушками;",
            "• выполнение социального действия с игрушкой по памяти (не применяя проб);",
            "• отраженное повторение простого моторного акта или социального действия с предметом после выполнения совместносо взрослым;",
            "• узнавание знакомых людей и речевых обращений;",
            "• способность предвосхищать действие, ситуацию из тех, что часто происходят в ежедневной жизни, узнавание знакомых людей, предметов.",
          ],
          content: [
            "формирование умения осуществлять ориентировку на источник звука и определять на слух его направление при разном расположении предмета;",
            "поддержание стремления и развитиенавыка использования движений рук при выполнении социальных действий с предметами, обследования и анализа перцептивных ощущений при ориентировке в окружающей среде;",
            "создание условий для развития у ребенка восприятия с опорой на сохранные анализаторы, при подкреплении тактильными, вибрационными и обонятельными ощущениями;",
            "формирование эмоционального отклика и социального поведения на изменение интонации голоса (ласковое или строгое обращение, от громкого звучания голоса до шепота);",
            "формирование навыка узнавания и различения звуков окружающей среды достаточной громкости;",
            "увеличение продолжительности и качества действий с предметами (манипулятивных, специфических и орудийных), осуществляемых под контролем зрительным или тактильного восприятия;",
            "расширение объема зрительного восприятия (полей зрения) за счет выполнения различных социальных действий с двумя близко расположенными игрушками;",
            "формирование умения осуществлять различные социальные действия с одним предметом, одинаковые действия с разными предметами путем осуществления практических проб или перебора вариантов, за счет исключения нерезультативных;",
            "формирование умения брать предметы с поверхности, используя различные захваты в зависимости от формы и величины (ладонный, щипковый, пинцентный и др.);",
            "формирование навыка узнавания близких людей по внешнему виду, голосу, запаху, а также предметов;",
            "формирование ориентировки на свое отражение в зеркале, узнавание своих вещей, любимых видов пищи;",
            "формирование умений обследовать лица близких взрослых, узнавать знакомые контуры, обследовать себя, при отсутствии выраженных нарушений зрения – узнавать себя в зеркале;  ",
            "формирование поискового поведения при исчезновении сенсорных стимулов из поля восприятия;",
            "развитие зрительно-моторной координации;",
            "формирование навыка отраженного повторения простого моторного акта или социального действия с предметом после его выполнения в совместной со взрослым деятельности, т.е. развитие имитации. ",
          ],
          tasks: [
            {
              id: 1,
              target:
                "создание условий для укрепления мышц ног и рук; закрепление навыка опоры на ножки, отталкивания руками (катание на большом мяче), опоры на руки для удержания позы тела.",
              material: "небольшая игрушка удобная для захвата (погремушка) с крутящейся деталью.",
              description:
                "педагог располагает ребенка в удобном для игры положении, устанавливает с ним эмоционально-положительный контакт и привлекает внимание к игрушке с крутящимся элементом, помогает «рука в руку» выполнить результативную манипуляцию, наблюдает за действием ребенка с игрушкой после обучения.",
              options: [
                "захватывает предмет, не осуществляет обследование деталей рукой, находит крутящийся элемент, не выполняет результативное движений, не приводит его в движение.",
                "ребенок осуществляет захват игрушки, удерживает ее в одной руке, обследует (ощупывает) другой рукой, находит крутящийся элемент и совершает результативное движение.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить умение предвосхищать появление игрушки.",
              material: "яркая контрастная игрушка со световым элементом.",
              description:
                "педагог располагает ребенка в удобном для игры положении, привлекает внимание и добивается фиксации взгляда на игрушке, которая расположена слева от ребенка, извлекает из нее звук и прячет ее. Затем располагает игрушку справа от ребенка, не показывая ее ему, извлекает звук и после привлекает его внимание и добивается сосредоточения взгляда. Третий раз извлекает звук из игрушки после перемещения ее влево и не показывает, следит за реакцией ребенка.",
              options: [
                "не переводит взгляд туда, откуда раздается звук и не ждет появления игрушки с другой стороны.",
                "переводит взгляд туда, откуда раздается звук и ждет появления игрушки, радуется, когда видит ее.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить умение извлекать из знакомой игрушки звук с помощью социального действия (сжимать, растягивать).",
              material: "знакомая для ребенка игрушка (издающая звук при сжимании или растягивании).",
              description: "педагог располагает ребенка в удобном положении и предлагает знакомую игрушку, наблюдает за его поведением.",
              options: [
                "не узнает свою игрушку, захватывает и не совершает социальное движение, не извлекает звук.",
                "узнает свою игрушку, захватывает и совершая социальное движение извлекает звук.",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 3,
          score: 0,
          targets: [
            "•  точное копирование знакомой цепочки социальных действий с предметом отраженно за взрослым (после выполнения в совместной деятельности);",
            "•  знание последовательности выполнения различных предметных действий и их цепочек с определенной социально обусловленной закономерностью;",
            "•  ситуативно-деловая форма общения;",
            "•  опознание и дифференцировка объектов;",
            "•  умение ориентироваться в собственном теле;",
            "•  осуществление практической ориентировки в свойствах предметов, их различение путем обследования зрением и рукой; ",
            "•  использование метода практических проб и последовательного применения ранее освоенных результативных действий для решения ситуативной практической задачи;",
            "•  согласование своих действие с действиями других детей и взрослых.",
          ],
          content: [
            "развитие навыка анализа ощущений, полученных в результате обследования предмета зрением и руками, опознавания и дифференциации объектов;",
            "расширение объема функциональных действий с предметами, выполнения из по памяти;",
            "формирование умения учитывать свойства и назначение предмета при выполнении игровых действий и предметной деятельности;",
            "формирование умения сравнивать одну группу предметов с другой методом практического примеривания, сопоставления (последовательно подкладывая один предмет к другому);",
            "формирование умения узнавать звучание игрушек при выборе из 2-3-4;",
            "формирование умения узнавать бытовые шумы;",
            "формирование умения узнавать звук музыкальных инструментов (барабан, бубен, металлофон, гармоника, дудка, свисток);",
            "формирование умения узнавать голоса близких взрослых, интонацию, понимать односложные просьбы и обращение по имени (для детей с нарушениями слуха в индивидуальных слуховых аппаратах и без них);",
            "осуществление выбора предмета и самостоятельное совершать результативную последовательность действий для достижения намеченной цели;",
            "развитие умения планировать деятельность, самостоятельно ее реализовывать, подводить итог и давать оценку результату;",
            "совершенствование координации и точности движений рук, обучение сложным социальным движениям: перелистывание, нажимание пальцами, кручение, нанизывание, закрывание и т.д.;",
            "развитие подражания цепочке социальных действий и формирование осознания их смысловой последовательности, навыка осмысленного выполнения, умения реализовывать их по памяти;",
            "формирование осознания объективных отношений, существующих между предметами;",
            "создание условий для осознания взаимосвязи между реальными предметами, их свойствами и назначением, действиями с ними и их обозначением;",
            "формирование практической ориентировки на внешний признак предметов, осознание разницы между предметами путем их обследования зрением и ощупывающими движениями рук;",
            "формирование умения соотносить предмет с его изображением (картинкой, барельефом);",
            "формирование умения группировать предметы по внешнему виду и форме (куклы и машинки; шарики и кубики);",
            "формирование навыка воссоздания целого предмета из его частей путем практических проб и ориентировки на образ предмета;",
            "совершенствование умения воздействоватьпредметом на предмет, выполнять орудийных действий;",
            "совершенствование навыка осязательного обследования при ориентировке в пространстве;",
            "развитие умения различать и сопоставлять некоторые свойства предметов путем ориентировки на свои перцептивные ощущения (по температуре, фактуре поверхности и свойствам материалов);",
            "развитие умения узнавать предметы по фактуре, форме и звукам, которые они издают при действии с ними (знакомые предметы обихода);",
            "формирование умения различать голоса окружающих людей достаточной громкости (для детей с нарушениями слуха ос слуховым аппаратом);",
            "формирование умения использовать обоняние для ориентировки в пространстве (запах еды, ванной комнаты, лекарства и др.);",
            "умение планировать и реализовывать знакомую последовательность действий.",
          ],
          tasks: [
            {
              id: 1,
              target: "выявить умение использовать предмет согласно его функциональному назначению.",
              material: "игрушка-персонаж (мишка, зайка), детская коляска.",
              description:
                "специалист во время эмоционально-положительного общения с ребенком создает игровую ситуацию. Берет игрушку, говорит: «Это Мишка. Будем его катать», сажает медведя в детскую коляску, ставит ее перед собой и толкает. После демонстрации действия взрослый предлагает ребенку повторить последовательность действий. Допускается однократное обучение выполнению действия методом «рука в руку», оказание помощи во время передвижения с помощью ходьбы с опорой и толкания коляски.",
              options: [
                "не использует предмет согласно его функциональному назначению, не сажает в коляску игрушку. не понимает, как придать движение коляске.",
                "использует предмет согласно его функциональному назначению, катает коляску.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить умение подражать деятельности взрослого, самостоятельно выполнять орудийное действие, игровую цепочку.",
              material: "банка с мячиками, ложка.",
              description:
                "во время эмоционально-положительного общения взрослый показывает ребенку банку, где лежат маленькие мячики. Специалист открывает крышу, берет ложку, опускает её в банку и, совершая зачерпывающее движение, подхватывает мяч, достает его из банки, показывает ребенку. Затем совместно «рука в руку» с ребенком последовательно выполняет результативную цепочку действий, перекладывает мячик ложкой из банки в другую ёмкость, или кладет на ковёр перед ребенком и предлагает ему повторить её самостоятельно.",
              options: [
                "не выполняет орудийное действие, не запоминает и не повторяет последовательность действий с орудием, не захватывает мяч ложкой, не воспроизводит игровую цепочку;",
                "выполняет орудийное действие, запоминает и повторяет последовательность действий с орудием, воспроизводит игровую цепочку правильно: захватывает мяч ложкой и вынимает его из банки, кладет в емкость или на пол перед собой, т.е. так, как показал взрослый.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить умение подражать игровому действию (качать куклу).",
              material: "кукла.",
              description:
                "во время эмоционально-положительного общения взрослый показывает ребенку куклу и начинает её качать, напевает колыбельную, после чего даёт ребенку куклу, выполняет совместно с ним качающее движение и напевает колыбельную, после чего просит ребенка: «Покачай куклу, спой ей песенку» и наблюдает за его поведением.",
              options: [
                "не понимает суть игровой ситуации, не подражает деятельности взрослого, не удерживает куклу в руках так, как показал взрослый, не выполняет качательные движения из стороны в сторону, не сопровождает действие звуком или слогом;",
                "понимает суть игровой ситуации, подражает деятельности взрослого,удерживает куклу в руках так, как показал взрослый, выполняет качательные движения из стороны в сторону, сопровождает действие звуком или слогом, подражая взрослому.",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 4,
          score: 0,
          targets: [
            "• схематично упрощенно изображает простой предмет, воссоздает постройку по образцу, по инструкции взрослого;",
            "• выполняет движения под музыку, подражает простой схеме движений вслед за взрослым;",
            "• осознает себя, простой фразой информирует о своих эмоциях и желаниях, узнает свои вещи, понимает различия в эмоциональном состоянии взрослого, сопереживает;",
            "• зрительная ориентировка и вербальное обозначение сенсорных признаков предметов (цвет, форма, размер и количество);",
            "• выполнение по памяти последовательной цепочки действий с предметами, которая отражает действия или деятельность людей (игровые действия);",
            "• ориентировка на свойства и качество предметов при их использовании;",
            "• соблюдение социально заданной последовательности действий из существующих в опыте.",
          ],
          content: [
            "развитие навыка ориентировки на свойства предметов, различения и объединения в группы согласно заданному, значимому сенсорному признаку;",
            "совершенствование качества целенаправленных предметно-орудийных действий в процессе выполнения игровой и продуктивной деятельности;",
            "использование накопленного практического опыта при ориентировке на внешние признаки предметов (цвет, форма, величина);",
            "формирование умения дифференцировать предметы по функциональному назначению;",
            "развитие практических способов ориентировки (пробы, примеривание) и освоение способа зрительной ориентировки в свойствах предмета, ситуации;",
            "формирование умения сравнивать предметы по величине;",
            "формировать умение выделять 1, 2 и много предметов из группы;",
            "формировать умение сопоставлять равные по количеству множества предметов: «одинаково»;",
            "формирование умения ориентироваться в пространстве путем практической и зрительной ориентировки;",
            "формирование умения определять и устанавливать взаимосвязи между пространственным положением предметов в помещении: шкаф, кровать, игрушки;",
            "обучение ориентировке на плоскости листа;",
            "обучение конструированию, рисуночной деятельности и моделированию путем ориентировки на рабочей поверхности, в пространстве листа;",
            "развитие подражания новым простым схемам действий;",
            "развитие навыка достижения поставленных целей путем выбора и воспроизведения результативной последовательности действий по памяти, при затруднении использования метода целенаправленных практических проб;",
            "воссоздание знакомых реальных предметов в виде конструкций и моделей из 2-3-4 частей;",
            "формирование умения наблюдать за изменениями в погоде.",
          ],
          tasks: [
            {
              id: 1,
              target:
                "выявить умение выполнять по памяти цепочку игровых действий с предметами, связанных между собой социальным контекстом, отображая деятельность людей.",
              material: "игрушки персонажи, детская мебель, посуда.",
              description:
                "во время эмоционально-положительного общения взрослый предлагает ребенку организовать игру: покормить куклу, положить куклу спать, построить гараж для машины. Инструкцию даёт вербально или жестово-вербально. Использует простые фразы: «Посмотри у меня игрушки. Все хотят спать/есть. Покорми куклу/мишку и т.п. Вот кубики. Построй кроватку. Положи игрушку спать. Вот кубики. Построй гараж. Поставь туда машину». Разрешается однократно продемонстрировать верный образец деятельности. Выполнить последовательно результативную цепочку действий совместно с ребенком «рука в руку». Затем предложить повторить деятельность самостоятельно.",
              options: [
                "не выполняет по памяти цепочку игровых действий с предметами, не может отобразить деятельностью людей;",
                "выполняет по памяти цепочку игровых действий с предметами, связанных между собой социальным контекстом, отображает деятельностью людей.",
              ],
              checked: false,
            },
            {
              id: 2,
              target:
                "выявить умение с помощью ориентировки на свои перцептивные ощущения находить способ решения проблемной ситуации, используя метод проб и ошибок.",
              material: "пластиковая игрушка-конструктор, которую можно разобрать на 3-4 части и собрать.",
              description:
                "во время эмоционально-положительного общения взрослый демонстрирует игрушку, разбирает её и просит ребенка собрать части, сделать целой игрушку вновь. Говорит: «Посмотри. Вот машинка/мишка/лошадка. Я её ломаю. Разъединяю части. Вот так. А ты собери. Сделай игрушку целой». Разрешается однократно продемонстрировать верный образец деятельности. Выполнить последовательно результативную цепочку действий совместно с ребенком «рука в руку». Затем предложить повторить деятельность самостоятельно.",
              options: [
                "не способен найти способ решения проблемной ситуации, результативно использовать метод проб и ошибок, не оперирует образом-восприятия целого и не осуществляет ориентировку на свои перцептивные ощущения;",
                "находит способ решения проблемной ситуации, используя метод проб и ошибок, делает игрушку целой, ориентируется на образ-восприятие целого и свои перцептивные ощущения.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить умение распределять предметы в группы путём практической ориентировки на сенсорный признак – форму.",
              material:
                "предметы трёх форм: круг (тарелочка, часы, яблоко), треугольник (ёлочка, кусочек сыра, флажок), квадрат (ластик, коробка, пуговица).",
              description:
                "во время эмоционально-положительного общения взрослый демонстрирует ребенку предметы, помогает ему обследовать их руками, действуя «рука в руку», обращает внимание на форму. После чего просит разложить их по форме в группы. Располагает в ряд кубики трёх форм: круглый, треугольный, квадратный. Инструкцию предъявляет вербально или жестово-вербально, допускается однократная демонстрация верного образца действия, разъясняя условия/правила выполнения. Кладёт один предмет рядом с кубиком аналогичной формы и просит ребенка разложить другие предметы также. Говорит: «Посмотри. Вот игрушки. Давай посмотрим. Какой они формы. Это круглая. Это квадратная. Это треугольная. Вот кубики. Сюда кладу круглый. Тут квадратный. Тут треугольный. Беру ёлочку. Кладу к треугольнику. Они одинаковой формы. Теперь ты. Разложи другие предметы так же,как я. Предметы определённой формы клади к кубику такой же формы». Можно выполнить результативную цепочку действий совместно с ребенком, «рука в руку». Затем предложить повторить деятельность самостоятельно.",
              options: [
                "не может распределить предметы в группы;",
                "распределяет предметы в группы путём выполнения практической ориентировки на свои перцептивные ощущения и форму предмета.",
              ],
              checked: false,
            },
          ],
        },
      ],
    },
    {
      id: 4,
      title: "Характеристика речевого развития",
      setsToShow: [],
      currentSet: null,
      score: 0,
      sets: [
        {
          period: 1,
          score: 0,
          targets: [
            "•	появление плача, крика в ситуации дискомфорта, успокоение при взятии на руки; ",
            "•	дифференцированные мимические проявления и поведение при ощущении комфорта и дискомфорта;",
            "•	имитация мимических движений;",
            "•	использование в общении непреднамеренной несимволической коммуникации.",
          ],
          content: [
            "формирование моторной готовности к непроизвольному воспроизведению артикуляционных поз и элементарной речевой коммуникации (изменение мимики во время общения со взрослым);",
            "стимуляция голосовой активности путем пассивной гимнастики;",
            "активизация мимических проявлений, движений губ, языка при попадании на них пищи;",
            "формирование невербальных средств общения (мимика, движения тела и рук); ",
            "стимуляция восприятия голоса взрослого на тактильно-вибрационной основе;",
            "вызывание гласных и согласных звуков раннего онтогенеза во время проведения дыхательной гимнастики и в минуты общения со взрослым;",
            "стимулирование внимания ребенка к речи взрослого, изменениям интонации и силы голоса.",
          ],
          tasks: [
            {
              id: 1,
              target:
                "выявить у ребенка способность плачем реагировать на дискомфорт(чувство голода, холод), непроизвольно использовать его как средство влияния на поведение взрослого.",
              material: "не требуется.",
              description: "специалист непосредственно перед началом кормления наблюдает за поведением ребенка.",
              options: [
                "при наступлении времени кормления поведение не меняется или становится более беспокойным, чем обычно, на лице появляется гримаса страдания, не плачет.",
                "в ситуации дискомфорта начинает плакать, успокаивается при взятии на руки, совершает движения губами.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "возникновение у ребенка речевых звуков при насыщении и комфорте (после кормления).",
              material: "не требуется.",
              description:
                "взрослый ожидает окончания кормления ребенка, наблюдает в течение 1 минуты за его поведением. Затем, взрослый подносит руки ребенка к своему лицу, к шее для ощущения им вибрации, произносит простые мелодичные звуки (у-у, и-и, ги), двигая пальцами вокруг губ ребенка, растягивая их в улыбку, вытягивает вперед.",
              options: [
                "поведение ребенка не меняется, редко возникает непроизвольная двигательная активность.",
                "при насыщении и комфорте (после кормления) у ребенка отмечается изменение мимики (сосредоточение, удовольствие), мимолетная улыбка, возникают отдельные звуки (а-а-а, г, х, ки).",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить у ребенка способность мимикой и голосовыми реакциями отображать свое эмоциональное состояние.",
              material: "не требуется.",
              description: "взрослый фиксирует свое внимание на поведении ребенка во время смены подгузника или умывании.",
              options: [
                "продолжительное время сохраняет спокойствие, мимика остается неизменной, плач не возникает.",
                "в ответ на дискомфорт реагирует мимикой недовольства, плачем с постепенным увеличением громкости голоса.",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 2,
          score: 0,
          targets: [
            "• копирование звуков гуления и лепета, интонирование в зависимости от ситуации и эмоционального состояния;",
            "• выражение своего отношения к ситуации в виде интонационно окрашенной цепочки звуков речи;",
            "• понимание в ограниченном объеме (не более 5 слов, 2-3 жестов или перцептивных цепочек) взаимосвязи между жестом и действием, жестом и предметом;",
            "• умение выполнять действия или находить предмет путем ориентировки на знаковый эталон после демонстрации действия взрослым.",
          ],
          content: [
            "развитие ситуативно-личностного и периодически возникающего в знакомой ситуации ситуативно-делового общения, как ведущей формы сотрудничества со взрослым;",
            "активизация и поддержание речевых звуков в момент контакта ребенка с близким взрослым, при выполнении гимнастики и действий с игрушками;",
            "стимуляция движений артикуляционного аппарата за счет выполнения массажа и пассивной артикуляционной гимнастики;",
            "формирование потребности использования руки как средства коммуникации, имитация социальных движений руки, жестов;",
            "развитие слухового или тактильно-вибрационного восприятия голоса другого человека, понимания символического значения перцептивного воздействия (прикосновение);",
            "формирование умения различать интонации взрослых, подкрепляя это соответствующей мимикой, звуком, тактильным воздействием;",
            "формирование навыка нахождения предмета по звуку, выполнения действия с ним или изменения поведения по речевому или тактильному обращению взрослого;",
            "формирование умения оказывать влияние на поведение взрослых с помощью интонированных звуков речи, мимики, социальных жестов;",
            "развитие умения отраженно за взрослым повторять знакомые и новые речевые звуки, слоги;",
            "формирование навыка согласования движений со словом в знакомых эмоционально-подвижных играх, выполнения движений с речевым сопровождением в хорошо известной игровой ситуации (по памяти);",
            "развитие лепета как важного компонента речевого развития;",
            "формирование умения обозначать слогом эмоциональное состояние, ситуацию, действие, предмет.",
          ],
          tasks: [
            {
              id: 1,
              target: "выявить наличие у ребенка продолжительногогуленияи первых слогов лепета.",
              material: "не требуется.",
              description:
                "педагог располагает ребенка в комфортном для игры положении, устанавливает с ним эмоционально-положительный контакт: поглаживает, эмоционально разговаривает с ним. Затем предлагает близкому взрослому эмоционально поговорить с ребенком. Также в течение  всего обследования, педагог отмечает издаваемые ребенком звуки.",
              options: [
                "отсутствие лепетных слогов, непродолжительное гуление, редкие вокализации во время бодрствования;",
                "действуя с игрушками во время бодрствования,регулярно продолжительно гулит (произносит отдельные звуки), лепечет (произносит отдельные лепетные слоги или цепочки слогов).",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить изменение интонации гуления, лепета в зависимости от эмоционального состояния, ситуации.",
              material: "не требуется.",
              description:
                "взрослый берет ребенка на руки, ласково общается с ним, после чего кладет его в кроватку и отходит на некоторое расстояние, исчезает из поля зрения.",
              options: [
                "изменения интонации звуков гуления и лепета, мимики при смене настроения, ситуации не отмечается.",
                "на руках у взрослого ребенок оживляется, в ответ на общение с ним начинает гулить, произносит слоги лепета или цепочку слогов лепета; речевые звуки звонкие, на лице появляется мимика удовольствия, радости. Оставленный один в кровати, ребенок произносит звуки гуления и лепета реже, тише, более протяжно, мимика со спокойной, удивленной сменяется грустной, недовольной.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить у ребенка интонированное гуление и лепет в качестве ответной реакции на эмоциональное общение.",
              material: "не требуются.",
              description:
                "педагог располагает ребенка в комфортном для игры положении, устанавливает с ним эмоционально-положительный контакт: поглаживает, эмоционально разговаривает, меняет интонацию голоса и мимику, позволяет ребенку дотронуться до лица, мягко тормошит и щекочет ребенка, шагает пальцами по его рукам и ногам, удаляет и приближает свое лицо, наблюдает за реакцией.",
              options: [
                "отсутствие или единичные, редко возникающие звуки гуления и лепета без интонирования.",
                "ребенок активно реагирует на общение, откликается, издает звуки гуления и лепета, по-разному интонирует их, имитирует/отображает мимику взрослого (улыбается, хмурится).",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 3,
          score: 0,
          targets: [
            "•	изменение поведения и выполнение действия в зависимости от жестового или речевого обращения взрослого;",
            "•	подражание слоговой цепочке с пониманием её социального смысла;",
            "•	проявление инициативы, желания общения, информирование о своем состоянии и потребностях с помощью доступных средств коммуникации.",
          ],
          content: [
            "создание условий для осознания взаимосвязи между движением, действием и его словесным обозначением;",
            "развитие невербальных средств коммуникации: увеличение числа социальных жестов, мимических проявлений, правильное их использование в различных жизненных ситуациях;",
            "развитие умения изменять поведение в соответствии с обращением взрослого;",
            "стимулирование звукоподражания и копирования речевых образцов, их осознанное использование в знакомой ситуации;",
            "развитие умения обозначать предмет и действие с ним словом, использование речи в разных ситуациях;",
            "развитие навыка информирования о своем состоянии и потребностях с помощью жестово-речевых средств;",
            "формирование понимания односложных и двусложных речевых или жестовых инструкций;",
            "развитие умения пользоваться движениями рук и пальцев как средством коммуникации, выполняя согласованные, направленные на другого человека движения рукой и тела;",
            "формирование умения осуществлять направленный выдох;",
            "стимуляция произношения голосом нормальной силы, высоты и тембра;",
            "увеличение количества регулярно произносимых речевых звуков, слогов и слов;",
            "выполнение артикуляционных движений отраженно за взрослым: улыбаться без напряжения, показывать верхние и нижние передние зубы, язык, вытягивать и сжимать губы, широко открывать рот;",
            "развитие силы голоса путем произношения гласных звуков тихо и громко, умения звать взрослого и общаться с ним голосом разной силы.",
          ],
          tasks: [
            {
              id: 1,
              target: "выявить умение копировать слоги лепета вслед за взрослым.",
              material: "не требуется.",
              description:
                "специалист на фоне эмоционально-положительного общения многократно выполняя действие с игрушкой ,демонстрирует речевой образец ребенку, стимулируя его тем самым к повторению. В ходе сотрудничества привлекает внимание ребенка к движениям губ взрослого. Произносит слоги медленно, громко, интонировано, повторяет речевой образец несколько раз.",
              options: [
                "ребенок не повторяет речевой образец вслед за взрослым, выполняет действия без речевого сопровождения;",
                "ребенок подражает слоговой цепочке и речевым образцам взрослого, сопровождает слогом действия с предметом.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить умение обращаться к взрослому с помощью социального жеста, сопровождая его речевым общением (слогом лепета).",
              material: "не требуется.",
              description:
                "специалист на фоне эмоционально-положительного контакта с ребенком демонстрирует ему жест и речевой образец, которым тот может обратиться ко взрослому с просьбой: «дай», «пить». При возникновении жажды подносит указательный палец ребенка к его губам, произносит: «пи-пи». Показывает игрушку, демонстрирует жест «дай» и сопровождает его словом. Допускается однократное совместное выполнение жеста вместе с ребенком, которое взрослый сопровождает речевым образцом.",
              options: [
                "не повторяет жест и лепетное слово так, как показал взрослый, не сообщает о своих желаниях и потребностях речью, не использует жест и слог;",
                "ребенок повторяет жест и лепетное слово так, как показал взрослый, сообщает о своих желаниях и потребностях социальным жестом и простым слогом.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить умение выполнить действие по слову.",
              material: "не требуется.",
              description:
                "специалист на фоне эмоционально-положительного контакта с ребенком, обращается к нему со знакомой просьбой: «Возьми игрушку», «Дай тете», «Кинь мяч», «Помаши ручкой». Разрешается однократно выполнить действие по слову совместно: взрослый действует руками ребенка. После обучения просит ребенка выполнить действие самостоятельно и фиксирует результат.",
              options: ["ребенок не выполняет простую знакомую просьбу.", "ребенок внимательно слушает взрослого, выполняет знакомую просьбу."],
              checked: false,
            },
          ],
        },
        {
          period: 4,
          score: 0,
          targets: [
            "• выполнение знакомой речевой просьбы, подражание простым речевым образцам;",
            "• использование жестово-мимических средств, отдельных слов и простых фраз с целью коммуникации;",
            "• сообщение взрослому о своих потребностях и состоянии в вербальной форме.",
            
          ],
          content: [
            "развитие умения понимать и выполнять простые вербально-жестовые инструкции;",
            "развитие понимания речи и умения выполнять действия по речевой инструкции;",
            "стимулирование применения речевых высказывания в общении со взрослыми или другим ребёнком: отдельные слова, словосочетания, фразы из 2-3 слов;",
            "формирование умения высказывать свои желания простыми фразами;",
            "формирование умения при общении использовать местоимение «я»;",
            "различение и воспроизведение речевых образцов с различной громкостью и интонацией: слоги, слова, фразы, произносимые тихо и громко;",
            "согласование ритма речи с музыкальным ритмом;",
            "обозначение слогами и словом звучаниемузыкальных инструментов – барабан, пианино, бубен; игра с игрушками с произнесением словосочетаний;",
            "различение на слух и воспроизведение разнообразных ритмов;",
            "определение на слух направления звука, источник которого расположен справа – слева – сзади – спереди, его нахождение и обозначение словом (барабан, бубен);",
            "развитие интонационной выразительности речи и обучение произношению фраз с повествовательной, вопросительной и восклицательной интонацией;",
            "совершенствование восприятия и понимания речи через опознание предметов по их речевому описанию (2-3 простых предложения из знакомых ребенку слов);",
            "формирование умения задавать вопросы и отвечать на них («Что это? Кто это? Где мяч?»);",
            "развитие умения составлять сообщение о себе, своих занятиях, близких людях;",
            "формирование умения описывать предметы (животных) с указанием цвета, формы, величины, материала, назначения и других признаков.",
          ],
          tasks: [
            {
              id: 1,
              target: "выявить умение подражать простым речевым образцам, обозначать действие и предмет словом.",
              material: "любые игрушки и предметы обихода.",
              description:
                "специалист в ходе эмоционально-положительного общения демонстрирует ребенку игрушку и действие с ней, называет игрушку и действие, даёт ребенку игрушку и просит выполнить с ней действие с речевым сопровождением, как взрослый. Например, новое существительное и глагол, прилагательное: «Мишка упал, бах». Допускается повторение игровой ситуации, обучение отражённому воспроизведению каждого слова отдельно.",
              options: [
                "не подражает простым речевым образцам, обозначает или действие, или предмет словом, не объединяет два слова во фразу.",
                "подражает простым речевым образцам, обозначает действие и предмет словом, объединяет два слова во фразу.",
              ],
              checked: false,
            },
            {
              id: 2,
              target:
                "выявить умение использовать фразу из двух облегчённых слов в целях коммуникации, сообщать о своих желаниях, сопровождая речевое обращение жестом.",
              material: "любые игрушки или предметы обихода.",
              description:
                "специалист в ходе эмоционально-положительного общения с ребенком фиксирует внимание на способе, которым тот пользуется для сообщения о своих желаниях и потребностях: хочет получить игрушку, сходить в туалет, испытывает жажду, требует участия взрослого в деятельности, оказания помощи. Специалист может несколько раз продемонстрировать ребенку вербальный способ коммуникации.",
              options: [
                "не использует фразу из двух облегчённых или полных слов в целях коммуникации, не сообщает о своих желаниях и потребностях словом, пользуется жестово-вербальными средствами;",
                "использует фразу из двух облегчённых или полных слов в целях коммуникации, сообщает о своих желаниях и потребностях фразой из 2 слов, подкрепляя высказывание мимикой и жестом.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить умение выполнять простую речевую инструкцию, просьбу взрослого в знакомой ситуации.",
              material: "любые игрушки и предметы обихода.",
              description:
                "специалист во время эмоционально-положительного общения с ребенком, обращается к нему с просьбой: «Возьми кубики. Положи в коробку. Возьми мишку. Посади мишку на стул». Жестами речевую просьбу не подкрепляет. Разрешается несколько раз выполнить последовательно результативную цепочку действий совместно с ребенком «рука в руку». Вначале обучить выполнять однуцепочку действий, потом другую. После чего попросить ребенка выполнить одну из них самостоятельно.",
              options: ["не выполняет простую просьбу, речевую инструкцию.", "выполняет простую просьбу, речевую инструкцию."],
              checked: false,
            },
          ],
        },
      ],
    },
    {
      id: 5,
      title: "Характеристика художественно-эстетического развития",
      setsToShow: [],
      currentSet: null,
      score: 0,
      sets: [
        {
          period: 1,
          score: 0,
          targets: [
            "•	мимика удовольствия, улыбка при звучании голоса матери (пение колыбельной);",
            "•	изменения поведения при звучании спокойной приятной музыки.",
          ],
          content: [
            "формирование сосредоточения и интереса к звукам окружающей среды, музыке, пению близкого взрослого;",
            "фиксация внимания на звучании музыкальных игрушек;",
            "формирование умения демонстрировать потребность в звучании знакомой мелодии с помощью двигательно-голосовой активности;",
            "формирование различных социальных ответов на звучание музыки (замирание, сосредоточение, поисковые реакции глазами, головой, телом).",
          ],
          tasks: [
            {
              id: 1,
              target: "выявить умение изменять поведение (успокаиваться) при напевании колыбельной песни.",
              material: "не требуется, взрослый напевает колыбельную песню («Колыбельная медведицы», «Спят усталые игрушки» и др.).",
              description:
                "во время беспокойства или плача ребенка, взрослый берет его на руки и напевает колыбельную песню, фиксирует изменения в поведении.",
              options: [
                "ребенок не успокаивается.",
                "ребенок спустя некоторое время успокаивается, на лице появляется мимика покоя или удовольствия.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить интерес ребенка к ярким изображениям.",
              material: "крупная яркая картина с контрастными деталями.",
              description:
                "взрослый берет ребенка на руки, располагает непосредственно перед его лицом на расстоянии 0,4 м. картину и наблюдает за его поведением.",
              options: [
                "взгляд на изображении не фиксирует, остается безразличен.",
                "кратковременно фиксирует взгляд на изображении, на лице возникает мимика сосредоточения, двигательная активность притормаживается.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить сосредоточение при изменении музыкального ритма.",
              material: "аудио запись марша.",
              description:
                "взрослый, удерживая ребенка на руках, пританцовывает с ним в такт ритмичной мелодии, через несколько секунд звучание музыки прекращается.",
              options: [
                "поведение ребенка не меняется.",
                "наблюдается двигательное сосредоточение при звучании марша, расслабление в состоянии покоя",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 2,
          score: 0,
          targets: [
            "•	успокаиваться при звучании знакомой мелодии или голоса, засыпать под определенную спокойную музыку или звучание музыкальной игрушки; реагировать на звучание разных музыкальных произведений.",
            "•	проявление эмоционального положительного отклика на звучание знакомой мелодии.",
          ],
          content: [
            "успокаивается при звучании знакомой мелодии или голоса;",
            "по-разному реагирует на звучание музыкальных произведений в медленном и быстром ритме;",
            "согласует движения с характером мелодии;",
            "движениями и мимикой сообщает об положительном отношении к звучанию знакомой мелодии.",
          ],
          tasks: [
            {
              id: 1,
              target: "выявить наличие ориентировки/узнавания знакомой музыки.",
              material: "запись любой известной ребенку детской песни.",
              description:
                "педагог налаживает эмоционально-положительный контакт с ребенком и включает/напевает знакомую ребенку песню, наблюдает за реакцией.",
              options: [
                "не реагирует на знакомую мелодию.",
                "у ребенка изменяется выражение лица и двигательная активность, когда он слышит знакомую музыку, повторяет знакомое движение вслед за взрослым.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить наличие предпочтений к определенным игровым стимулам.",
              material: "машинка, кукла, мяч.",
              description:
                "педагог располагает ребенка в комфортном для игры положении, устанавливает с ним эмоционально-положительный контакт. Затем педагог организует игру с тремя предметами: дает обследовать машинку, мяч, куклу и наблюдает за поведением ребенка.",
              options: [
                "не выказывает предпочтения какой-либо игрушке.",
                "обследовав три предмета, ребенок начинает больше уделять внимание одному из них, обследует рукой, выполняет действие с учетом функционального назначения (катает, качает).",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить наличие восприятия музыкального темпа.",
              material: "записи ритмичной детской музыки и колыбельной.",
              description:
                "педагог налаживает эмоционально-положительный контакт с ребенком и предлагает для прослушивания поочередно ритмичную и колыбельную мелодии, каждую по 30 секунд, наблюдает за поведением ребенка. Если во время прослушивания мелодии в течение первых 15 секунд двигательной реакции не наблюдается, педагог демонстрирует танцевальные движения (быстрые или медленные), при необходимости помогает ребенку осуществить их, затем снова наблюдает за реакциями.",
              options: [
                "движения под музыку отсутствуют или не согласуются с ритмом и характером мелодии;",
                "ребенок совершает ритмичные движения или раскачивается в зависимости от темпа мелодии самостоятельно или после оказания обучающей помощи педагога.",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 3,
          score: 0,
          targets: [
            "•  дифференцированные мимические проявления и изменение поведения при смене быстрой мелодии на медленную;",
            "•  выполнение знакомых социальных движений в такт мелодии;",
            "•  извлечение звука из музыкальной игрушки, музыкальных инструментов;",
            "•  создает простейшие постройки из конструктора;",
            "•  удерживает карандаш в руке, проводит прямые, закругленные и прерывистые линии.",
          ],
          content: [
            "знакомство с функциональными возможностями музыкальных инструментов; расширение репертуара действий с музыкальными игрушками",
            "обучение движениям согласно ритму и настроению мелодии;",
            "создание условий для развития у детей интереса к звучанию музыки, накопления опыта восприятия новых звуков музыкальных игрушек;",
            "стимуляция и развитие интереса к прослушиванию музыкальных произведений;",
            "развитие умения изменять поведение в зависимости от характера музыки (спокойная, маршеобразная, плясовая), выполнять движения в такт музыки;",
            "формирование умения информировать взрослого о своем предпочтении определенного музыкального произведения или игрушки;",
            "развитие слухового восприятия, расширение количества узнаваемых звуков, музыкальных игрушек;",
            "развитие умения самостоятельно подбирать результативное социальное движение и извлекать из музыкального инструмента звук с учетом его функциональных возможностей;",
            "формирование навыка тактильного обследования предмета;",
            "обучение выполнению простых действий с пластилином: разминание, соединение/разъединение, раскатывание и др.;",
            "формирование умения выполнять простые поделки из пластилина;",
            "формирование умения обследовать и узнавать знакомые предметы, выполненные в виде аппликации;",
            "обучение простым приемам аппликации (наклеивание, соединение/разъединение);",
            "формирование навыка подражания действиями взрослого при выполнении аппликации;",
            "формирование умения узнавать плоскостное изображение предмета и сравнивать его с реальным объектом;",
            "обучение выполнению орудийного действия с карандашом и формирование таких простых графических навыков, как рисование прямых, замкнутых линий, черкания;",
            "формирование навыка подражания простым графическим движениям карандашом;",
            "формирование умения узнавать объекты, выполненные с помощью деталей конструктора, и сравнивать их с реальными объектами, называть основные элементы доступным коммуникативным способом;",
            "формирование умения выполнять постройку из 1-3 деталей по образцу;",
            "формирование умения последовательно выполнять постройку из 2-3 деталей по подражанию действиям взрослого;",
            "формирование умения использовать детали с учетом их конструктивных свойств за счет ориентировки на их сенсорные характеристики и свойства.",
          ],
          tasks: [
            {
              id: 1,
              target:
                "выявить умение узнавать и по памяти воспроизводить движение и/или слово согласно речевому сопровождению и ритму знакомой мелодии, используя гласные и согласные звуки.",
              material: "запись знакомой мелодии «Кукла с Мишкой» или любая другая.",
              description:
                "взрослый демонстрирует образец поведения ребенку, жестом и голосом указывает место в музыкальном произведении, где надо произнести слово или выполнить жест. Например, звучит: «Мишка с куклой бойко топают…» взрослый поет «то-то-то» и выполняет движение ногой, звучит: «И в ладоши звонко хлопают….» взрослый поет «оп-оп-оп» и хлопает в ладоши. Разрешается однократное выполнение движения совместно с ребенком. Фиксируется результат обучения.",
              options: [
                "не согласует движение с ритмом мелодии и не произносит слово, когда это делает взрослый, не согласует движения с ритмом музыкального произведения и поведением взрослого;",
                "согласует движение с ритмом мелодии и произносит слово отраженно за взрослым, согласует движения и поведение с ритмом музыкального произведения.",
              ],
              checked: false,
            },
            {
              id: 2,
              target: "выявить умения согласовывать свои эмоции и движения с музыкальным ритмом.",
              material: "запись знакомой мелодии быстрого и спокойного темпа.",
              description:
                "на фоне эмоционально-положительного контакта с ребенком специалист либо сам пропевает знакомую мелодию, либо включает запись. Допускается однократная демонстрация выполнение движений (притоптывание, размахивание руками, покачивание корпусом) под быстрый темп мелодии и выражение определенных эмоциональных проявлений (улыбка, смех) и изменение движений (опускание рук вдоль тела, остановка на одном месте) при звучании спокойного темпа и соответственно выражение других эмоциональных проявлений (спокойное выражение на лице, грусть, обида). Повторное выполнение совместно с ребенком. После чего самостоятельное выполнение по простой вербальной или тактильно-вибрационной инструкции.",
              options: [
                "ребенок не понимает изменение темпа, не меняет своих движений, мимики и поведения;",
                "ребенок, подражая взрослому, выполняет определённые движения при звучании быстрой мелодии, согласует поведение с темпом мелодии.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить умение выполнять определённые движения в соответствии с заданным музыкальным ритмом, целенаправленность действий.",
              material: "игрушка-самолет, знакомая мелодия «Чух-чух.. мчится поезд во весь дух…».",
              description:
                "на фоне эмоционально-положительного контакта с ребенком специалист привлекает его внимание к игрушке-самолёту, показывает, как он летает, разводит руки в стороны и имитирует движение полета, произносит «Самолет летит и гудит «у-у-у». Потом взрослый включает знакомую песню про поезд, складывает определённым образом руки (прижимает к корпусу согнутые под углом 90 градусов руки, поочередно двигая вперёд то одну, то другую).Допускается однократная демонстрация верного образца деятельности. Выполнить совместно «рука в руку» движения «полета самолета» и поезда. Затем, попросить ребенка выполнить просьбу самостоятельно.",
              options: [
                "ребенок не понимает просьбы взрослого, не выполняет движений, не использует движение в качестве знакового обозначения;",
                "ребенок выполняет определенные движения в соответствии с ситуацией, показывает, как летит самолет, движется поезд, радуется игре, понимает знаковое значение движения и смысл игровой ситуации.  ",
              ],
              checked: false,
            },
          ],
        },
        {
          period: 4,
          score: 0,
          targets: [
            "умеет извлекать звук из музыкальных инструментов, выполняет действие по речевой инструкции;",
            "выполняет движения под музыку, ориентируясь на движения взрослого;",
            "выполнять знакомую простую творческую деятельность: аппликацию, рисунок, конструирование по памяти;",
            "участвует в совместной продуктивной деятельности, выполняет действия по речевой инструкции, ориентируясь на образец.",
            
          ],
          content: [
            "увеличение длительности прослушивания музыкальных произведений (2-3 минуты);",
            "закрепление умения самостоятельно подбирать результативное социальное движение и извлекать из музыкального инструмента звук с учётом функциональных свойств;",
            "развитие навыка подражания движениям взрослого при звучании знакомой музыки;",
            "стимулирование подпевания знакомой песне или музыке;",
            "развитие умения изменять движения в соответствии с изменением ритма и характером мелодии;",
            "формирование навыка воспроизведения простых музыкальных ритмов;",
            "формирование умения выбирать музыкальный инструмент по образцу, по речевой инструкции;",
            "развитие навыка узнавания и различения хорошо знакомых музыкальных произведений;",
            "формирование навыка ориентировки и выполнения простых движений по образцу при лепке;",
            "обучение простым продуктивным и конструктивным действиям, последовательному их выполнению по памяти в соответствии с заданной целью;",
            "формирование умения располагать и наклеивать (при помощи взрослого) детали предмета из бумаги на плоскость с ориентировкой на образец;",
            "формирование умения принимать участие в коллективной работе;",
            "развитие графических навыков; умения пользоваться карандашом, фломастером;",
            "формирование умения выполнять различные линии, рисовать, ориентируясь на образец.",
            
            
          ],
          tasks: [
            {
              id: 1,
              target: "выявить умение согласовывать движения с характером мелодии (быстрая, медленная).",
              material: "запись марша и колыбельной мелодии.",
              description:
                "на фоне эмоционально-положительного контакта с ребенком специалист поочередно включает запись марша и колыбельной мелодии, демонстрирует ребенку последовательно два разных движения: марширует и раскачивается из стороны в сторону. Между музыкальными отрывками делает паузу до 30-60 сек. Просит ребенка повторить за ним движение, согласовывать его с ритмом мелодии. Каждый музыкальный ритм предъявляется дважды. Разрешается однократно выполнить последовательно движениясовместно с ребенком, помогая ему почувствовать ритм мелодии, согласовать с ним движения. Затем, попросить ребенка выполнить их самостоятельно.",
              options: [
                "не понимает просьбу, не выбирает движение в соответствии характера мелодии, не согласует движение с ритмом.",
                "выполняет просьбу, выбирает движение в соответствии характера мелодии, согласует их с ритмом.",
              ],
              checked: false,
            },
            {
              id: 2,
              target:
                "выявить умение заряжаться эмоциональным состоянием музыкального произведения, подражая взрослому, воспроизводить слоги и слова, согласно ритму мелодии.",
              material: "запись известной детской песни.",
              description:
                "на фоне эмоционально-положительного контакта с ребенком специалист включает запись известной детской песни. Например: «Мы едем, едем, едем в далекие края», «Топ-топ веселей, своих ножек не жалей». Просит ребенка воспроизвести короткое слово в ритм мелодии.",
              options: [
                "не подражает взрослому, не воспроизводит слоги и слова, согласно ритму мелодии.",
                "подражает взрослому, воспроизводит слоги и слова, согласно ритму мелодии.",
              ],
              checked: false,
            },
            {
              id: 3,
              target: "выявить умение использовать орудие для реализации творческого замысла.",
              material: "карандаш, краски и кисть.",
              description:
                "на фоне эмоционально-положительного контакта с ребенком специалист привлекает его внимание к карандашу и просит ребенка нарисовать дождик, дорожку или солнышко. Разрешается однократно продемонстрировать верный образец деятельности. Нарисовать дождик, дорожку, солнышкосовместно с ребенком, действуя «рука в руку». Затем попросить ребенка выполнить просьбу самостоятельно.",
              options: [
                "не понимает функциональные возможности орудия, не реализует с его помощью творческого замысла.",
                "использует орудие для реализации творческого замысла.",
              ],
              checked: false,
            },
          ],
        },
      ],
    },
  ],

  emptyFieldsAreVisible: false,
};

export default initialState;
