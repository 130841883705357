import React from 'react'
import './AddInput.scss'
import AddIcon from '@material-ui/icons/Add'
import PublishIcon from '@material-ui/icons/Publish'

const AddInput = (props) => {
  const {
    id,
    name,
    active,
    onChange,
    value,
    onButtonClick,
    buttonLabel,
    inputLabel,
    onSubmitButtonClick,
  } = props

  return (
    <div className="add-option">
      {active ? (
        <div className='add-option__input'>
          <input
            id={id}
            name={name}
            type="text"
            value={value}
            onChange={onChange}
          />
          <label className="active" htmlFor={id}>
            {inputLabel}
          </label>
        </div>
      ) : null}
      <div className="add-option__buttons">
        <span className="grey-text">{buttonLabel}</span>
        {active ? (
          <PublishIcon
            className="orange-text add-option__button"
            fontSize="large"
            onClick={() => {
              onSubmitButtonClick()
              onButtonClick()
            }}
          />
        ) : (
          <AddIcon
            className="orange-text add-option__button"
            fontSize="large"
            onClick={onButtonClick}
          />
        )}
      </div>
    </div>
  )
}

export default AddInput
