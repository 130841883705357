import React from 'react'
import Characteristiс from './Characteristiс/Characteristiс'
import './RecommendationForm.scss'
import Datepicker from './Datepicker/Datepicker'
import MultipleSelect from './MultipleSelect/MultipleSelect'
import Schedule from './Schedule/Schedule'
import SingleSelect from './SingleSelect/SingleSelect'
import Survey from './Survey/Survey'
import AddInput from '../AddInput/AddInput'
import FamilyResoucesPreview from './FamilyResoucesPreview/FamilyResoucesPreview'
import FamilyResponsibilityPreview from './FamilyResponsibilityPreview/FamilyResponsibilityPreview'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy'
// import saveIcon from '../../img/SaveIcon.png'
// import loadIcon from '../../img/LoadIcon.png'
import Input from './Input/Input'

// Закоменчена сохранялка

const RecommendationForm = (props) => {
  const {
    token,
    userId,
    formData,
    submitRecomendationForm,
    changeBirthDate,
    changeTaskChecked,
    addSetToCharacteristic,
    showEmptyFields,
    changeDateOfEnrollment,
    changeDateOfFIlling,
    changeValueByNameOnTopLevel,
    setCheckedEstimateOptions,
    setCheckedSpecialists,
    changeScheduleStartTime,
    changeScheduleFinishTime,
    setSelectedByName,
    changeSpecialTrainingConditionsAnother,
    setSurveySelected,
    changeSurveyAnother,
    setNeedToAddSpecialist,
    changeSpecialistOptionToAdd,
    addSpecialistsOption,
    returnToDefaultSet,
    changeCharacteristicIsDone,
    save,
    // load,
  } = props
  const today = new Date()

  const saveButtonHandler = () => {
    save(formData)
  }

  const topLevelInputHandler = (event) => {
    changeValueByNameOnTopLevel(event.target.name, event.target.value)
  }
  const topLevelSelectHandler = (event) => {
    let selected = []

    if (typeof event.target.value === 'object') {
      event.target.value.forEach((s) => {
        if (s !== '') {
          selected.push(s)
        }
      })
    } else {
      selected = event.target.value
    }
    setSelectedByName(event.target.name, selected)
  }
  const submitHandler = (event) => {
    event.preventDefault()
    submitRecomendationForm(token, userId, formData)
  }
  const specialistsHandler = (event) => {
    setCheckedSpecialists(event.target.value)
  }
  const hasIPRAHandler = () => {
    changeValueByNameOnTopLevel('hasIPRA', !formData.hasIPRA)
  }
  const agreeWithPrivacyPolicyHandler = () => {
    changeValueByNameOnTopLevel(
      'agreeWithPrivacyPolicy',
      !formData.agreeWithPrivacyPolicy,
    )
  }

  const needTechnicalEquipmentHandler = () => {
    changeValueByNameOnTopLevel(
      'needTechnicalEquipment',
      !formData.needTechnicalEquipment,
    )
  }
  const specialTrainingConditionsAnotherHandler = (event) => {
    changeSpecialTrainingConditionsAnother(event.target.value)
  }
  const needToAddSpecialistHandler = () => {
    setNeedToAddSpecialist(!formData.specialists.needToAddOption)
  }
  const specialistsOptionToAddHandler = (event) => {
    changeSpecialistOptionToAdd(event.target.value)
  }
  const addSpecialistsOptionButtonHandler = () => {
    if (formData.specialists.optionToAdd !== '') {
      addSpecialistsOption(formData.specialists.optionToAdd)
    }
  }

  const surveysElements = formData.surveys.map((s) => {
    return (
      <Survey
        period={formData.basePeriod}
        {...s}
        key={s.id}
        selectHandler={(name, value) => setSurveySelected(s.id, name, value)}
        anotherChangeHandler={(name, value) =>
          changeSurveyAnother(s.id, name, value)
        }
      />
    )
  })

  const createEstimationsElements = () => {
    const estimationsElements = []

    formData.healthStatus.estimations.forEach((e) => {
      let isEnabled = false
      for (let i = 0; i < e.categories.length; i++) {
        if (formData.healthStatus.selected.includes(e.categories[i])) {
          isEnabled = true
          break
        }
      }

      if (isEnabled) {
        estimationsElements.push(
          <div key={e.id} className="select-field col s12">
            <MultipleSelect
              id={'estimate' + e.id}
              label={e.title}
              options={e.options}
              selected={e.selected}
              onChange={(event) => {
                setCheckedEstimateOptions(e.id, event.target.value)
              }}
            />
          </div>,
        )
      }
    })

    return estimationsElements
  }

  const estimateElements = createEstimationsElements()

  const characteristiсsElements = formData.characteristiсs.map((c) => {
    return (
      <Characteristiс
        {...c}
        key={c.id}
        changeTaskChecked={changeTaskChecked}
        addSetToCharacteristic={addSetToCharacteristic}
        basePeriod={formData.basePeriod}
        returnToDefaultSet={returnToDefaultSet}
        changeCharacteristicIsDone={changeCharacteristicIsDone}
        done={c.done}
      />
    )
  })

  const schedulesElements = formData.specialists.schedules.map((s) => {
    return (
      <Schedule
        {...s}
        key={s.id}
        changeScheduleStartTime={changeScheduleStartTime}
        changeScheduleFinishTime={changeScheduleFinishTime}
      />
    )
  })

  return (
    <>
      <form className="col s12 constructor" onSubmit={submitHandler}>
        <div className="row">
          <div className="col s12">
            <h2>Целевой раздел</h2>
          </div>

          {/* ДАТЫ */}
          <div className="col s12">
            <h3>Общие психолого-педагогические сведения обучающегося</h3>
          </div>
          <div className="input-field col s12">
            <Datepicker
              label="Дата заполнения документа"
              date={formData.dateOfFilling}
              selectHandler={changeDateOfFIlling}
              id={2}
            />
          </div>

          <div className="input-field col s12">
            <Datepicker
              label="Дата рождения ребенка"
              date={formData.birthDate}
              selectHandler={changeBirthDate}
              maxDate={today}
              id={1}
            />
          </div>

          <div className={`input-field col s12`}>
            <Datepicker
              label="Дата зачисления ребенка в образовательную организацию"
              date={formData.dateOfEnrollment}
              selectHandler={changeDateOfEnrollment}
              maxDate={today}
              id={3}
            />
          </div>

          {formData.dateOfEnrollment ? (
            <div className="col s12">
              <SingleSelect
                id="dynamics-select"
                name="dynamics"
                label="Динамика психического развития в условиях специального образования"
                selected={formData.dynamics.selected}
                options={formData.dynamics.options}
                onChange={topLevelSelectHandler}
              />
            </div>
          ) : null}

          {/* ИМЯ РЕБЕКА */}
          <div className="row">
            <div className="col s12">
              <div className="input-field col s6">
                <Input
                  label="Имя"
                  id="firstName"
                  type="text"
                  name="firstName"
                  className="validate"
                  required
                  value={formData.firstName}
                  onChange={topLevelInputHandler}
                />
              </div>

              <div className="input-field col s6">
                <Input
                  id="lastName"
                  name="lastName"
                  type="text"
                  className="validate"
                  required
                  value={formData.lastName}
                  onChange={topLevelInputHandler}
                  label="Фамилия"
                />
              </div>
            </div>
          </div>
        </div>

        {/* ПОСЛЕ ДАТЫ */}
        {formData.age === null ? null : (
          <>
            {/* ОБСЛЕДОВАНИЯ */}
            <h3>
              Результаты психолого-педагогического наблюдения за свободной
              деятельностью и поведением ребенка
            </h3>
            {surveysElements}

            {/* ЗДОРОВЬЕ И СПЕЦИАЛИСТЫ */}
            <h3>Дополнительная медико-социальная информация</h3>
            <div className="row">
              <div className="input-field col s12">
                <Input
                  id="recommendedProgramm"
                  type="text"
                  className="validate"
                  value={formData.recommendedProgram}
                  name="recommendedProgram"
                  onChange={topLevelInputHandler}
                  label="Рекомендованный ПМПК вариант образовательной программы (заполнить самостоятельно)"
                  helperText="Выбор из документов ПМПК"
                />
              </div>

              <div className="select-field col s12">
                <MultipleSelect
                  onChange={topLevelSelectHandler}
                  name="socialCategory"
                  id="socialCategory"
                  label="Социальная категория"
                  options={formData.socialCategory.options}
                  selected={formData.socialCategory.selected}
                />
              </div>

              <div className="input-field col s12">
                <p className="checkbox-wrapper">
                  <span className="ipra-span grey-text">Наличие ИПРА</span>
                  <label>
                    <input
                      name="hasIPRA"
                      type="checkbox"
                      checked={formData.hasIPRA}
                      onChange={hasIPRAHandler}
                    />
                    <span></span>
                  </label>
                </p>
              </div>

              <div className="col s12">
                <h4>
                  Сведения о состоянии здоровья ребенка по заключению
                  врачей-специалистов (класс МКБ 10)
                </h4>
              </div>

              <div className="select-field col s12">
                <MultipleSelect
                  id="diseases-category"
                  selected={formData.healthStatus.selected}
                  name="healthStatus"
                  onChange={topLevelSelectHandler}
                  options={formData.healthStatus.options}
                  label="Болезни"
                />
              </div>

              {estimateElements}

              <div className="input-field col s12">
                <Input
                  id="typesOfRehabination"
                  type="text"
                  className="validate"
                  value={formData.typesOfRehabilitation}
                  name="typesOfRehabilitation"
                  onChange={topLevelInputHandler}
                  label="Виды психолого-педагогической реабилитации (заполнить самостоятельно)"
                  helperText="Выбор из рекомендаций ИПРА, ПМПК"
                />
              </div>

              <div className="select-field col s12">
                <MultipleSelect
                  id="specialists"
                  name="specialists"
                  selected={formData.specialists.selected}
                  options={formData.specialists.options}
                  label="Специалисты, участвующие в образовании"
                  onChange={specialistsHandler}
                />
                <span className="helper-text grey-text">
                  Выбор из рекомендаций ИПРА, ПМПК
                </span>

                <AddInput
                  id="addSpecialist"
                  name="addSpecialist"
                  active={formData.specialists.needToAddOption}
                  onChange={specialistsOptionToAddHandler}
                  value={formData.specialists.optionToAdd}
                  onButtonClick={needToAddSpecialistHandler}
                  onSubmitButtonClick={addSpecialistsOptionButtonHandler}
                  buttonLabel="Добавить специалиста"
                  inputLabel="Введите название специалиста"
                />
              </div>

              {formData.specialists.selected.length ? (
                <h5>Расписание специалистов</h5>
              ) : null}
              <div className="col s12">{schedulesElements}</div>

              <div className="input-field col s12">
                <p className="ipra-checkbox-wrapper">
                  <span className="ipra-span grey-text">
                    Требуется специальное оснащение
                  </span>
                  <label>
                    <input
                      type="checkbox"
                      checked={formData.needTechnicalEquipment}
                      onChange={needTechnicalEquipmentHandler}
                    />
                    <span></span>
                  </label>
                </p>
              </div>

              {!formData.needTechnicalEquipment ? null : (
                <div className="select-field col s12">
                  <MultipleSelect
                    id="technicalEquipment"
                    name="technicalEquipment"
                    selected={formData.technicalEquipment.selected}
                    options={formData.technicalEquipment.options}
                    label="Специальные средства/техническое оснащение обучения "
                    onChange={topLevelSelectHandler}
                  />

                  <span className="helper-text grey-text">
                    Выбор из рекомендаций ИПРА, ПМПК
                  </span>
                </div>
              )}

              <div className="select-field col s12">
                <MultipleSelect
                  id="specialTrainingConditions"
                  name="specialTrainingConditions"
                  selected={formData.specialTrainingConditions.selected}
                  options={formData.specialTrainingConditions.options}
                  label="Специальные условия обучения детей"
                  onChange={topLevelSelectHandler}
                />
              </div>

              {formData.specialTrainingConditions.selected.includes(
                'Другое',
              ) ? (
                <div className="input-field col s12">
                  <Input
                    id="specialTrainingConditionsAnother"
                    name="specialTrainingConditionsAnother"
                    type="text"
                    value={formData.specialTrainingConditions.another}
                    onChange={specialTrainingConditionsAnotherHandler}
                    label="Другое"
                  />
                </div>
              ) : null}
            </div>

            {/* ИНФА О СЕМЬЕ */}
            <h3>Сведения о семье</h3>
            <div className="row">
              <div className="select-field col s12">
                <SingleSelect
                  id="familiyStatus"
                  name="familyStatus"
                  label="Состав семьи"
                  onChange={topLevelSelectHandler}
                  selected={formData.familyStatus.selected}
                  options={formData.familyStatus.options}
                />
              </div>

              <div className="input-field col s12 m6">
                <Input
                  id="childrenAmount"
                  type="number"
                  min="1"
                  className="validate"
                  value={formData.childrenAmount}
                  name="childrenAmount"
                  onChange={topLevelInputHandler}
                  label="Количество детей в семье"
                />
              </div>

              <div className="input-field col s12 m6">
                <Input
                  id="workingFamilyMembers"
                  type="number"
                  min="0"
                  className="validate"
                  value={formData.workingFamilyMembers}
                  name="workingFamilyMembers"
                  onChange={topLevelInputHandler}
                  label="Количество работающих членов семьи"
                />
              </div>

              <div className="select-field col s12">
                <SingleSelect
                  id="parentsEducation"
                  name="parentsEducation"
                  label="Образование родителей"
                  onChange={topLevelSelectHandler}
                  selected={formData.parentsEducation.selected}
                  options={formData.parentsEducation.options}
                />
              </div>

              <div className="select-field col s12">
                <SingleSelect
                  id="livingConditions"
                  name="livingConditions"
                  label="Жилищные условия"
                  onChange={topLevelSelectHandler}
                  selected={formData.livingConditions.selected}
                  options={formData.livingConditions.options}
                />
              </div>

              <div className="select-field col s12">
                <SingleSelect
                  id="financialSituation"
                  name="financialSituation"
                  label="Материальный достаток семьи"
                  onChange={topLevelSelectHandler}
                  selected={formData.financialSituation.selected}
                  options={formData.financialSituation.options}
                />
              </div>

              <div className="select-field col s12">
                <FamilyResoucesPreview
                  communicationPractice={
                    formData.communicationPractice.selected
                  }
                  participationInEducation={
                    formData.participationInEducation.selected
                  }
                  recommendationsImplementation={
                    formData.recommendationsImplementation.selected
                  }
                  financialSituation={formData.financialSituation.selected}
                  livingConditions={formData.livingConditions.selected}
                  parentsEducation={formData.parentsEducation.selected}
                  familyStatus={formData.familyStatus.selected}
                  workingFamilyMembers={formData.workingFamilyMembers}
                />
              </div>

              <div className="select-field col s12">
                <SingleSelect
                  id="recommendationsImplementation"
                  name="recommendationsImplementation"
                  label="Выполнение родителями педагогических рекомендаций"
                  onChange={topLevelSelectHandler}
                  selected={formData.recommendationsImplementation.selected}
                  options={formData.recommendationsImplementation.options}
                />
              </div>

              <div className="select-field col s12">
                <SingleSelect
                  id="participationInEducation"
                  name="participationInEducation"
                  label="Степень участия в специальном образовании"
                  onChange={topLevelSelectHandler}
                  selected={formData.participationInEducation.selected}
                  options={formData.participationInEducation.options}
                />
              </div>

              <div className="select-field col s12">
                <SingleSelect
                  id="communicationPractice"
                  name="communicationPractice"
                  label="Организация сотрудничества с семьей"
                  onChange={topLevelSelectHandler}
                  selected={formData.communicationPractice.selected}
                  options={formData.communicationPractice.options}
                />
              </div>
            </div>

            <FamilyResponsibilityPreview
              communicationPractice={formData.communicationPractice.selected}
              participationInEducation={
                formData.participationInEducation.selected
              }
              recommendationsImplementation={
                formData.recommendationsImplementation.selected
              }
            />

            {/* ОРГАНИЗАЦИОННЫЙ РАЗДЕЛ */}
            <h2>Организационный раздел</h2>
            <div className="row"></div>

            {/* ХАРАКТЕРИСТИКА */}
            <h2>Психолого-педагогическая характеристика ребенка</h2>
            {characteristiсsElements}
          </>
        )}

        <p className="red-text">{formData.message}</p>

        <div
          className="input-field col s12"
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
        >
          <button
            className="btn waves-effect orange waves-light authButton"
            type="submit"
            onClick={showEmptyFields}
            disabled={!formData.agreeWithPrivacyPolicy}
            style={{ marginRight: '10px', marginBottom: '5px' }}
          >
            Сформировать
          </button>
          <PrivacyPolicy
            checkboxValue={formData.agreeWithPrivacyPolicy}
            checkboxHandler={agreeWithPrivacyPolicyHandler}
          />
        </div>
      </form>
      {/* <div className="save-load">
        <div className="save-load__icon">
          <img
            src={saveIcon}
            onClick={saveButtonHandler}
            alt="save-load__save"
          />
        </div>
        <div className="save-load__icon">
          <img src={loadIcon} onClick={load} alt="save-load__load" />
        </div>
      </div> */}
    </>
  )
}

export default RecommendationForm
