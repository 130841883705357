import {
  getFileInfo,
  downloadFile as downloadFileRequest,
  deleteFile as deleteFileRequest,
} from '../api/api'

const initialState = { files: [] }

const SET_FILES = 'SET_FILES'

const cabinetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILES:
      return {
        ...state,
        files: [...action.files],
      }

    default:
      return state
  }
}

export const setFiles = (files) => {
  return {
    type: SET_FILES,
    files,
  }
}

export const getFiles = (token, userId) => (dispatch) => {
  getFileInfo(token, userId).then((res) => {
    dispatch(setFiles(res.data.files))
  })
}

export const downloadFile = (token, fileId, fileName) => (dispatch) => {
  downloadFileRequest(token, fileId, fileName)
}

export const deleteFile = (token, fileId, userId) => (dispatch) => {
  deleteFileRequest(token, fileId).finally(() => {
    dispatch(getFiles(token, userId))
  })
}

export default cabinetReducer
