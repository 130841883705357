import {
  forgotPassword as forgotPasswordRequest,
  login as loginRequest,
  registration,
} from "../api/api";
import { login } from "./authReducer";

const initialState = {
  form: {
    email: "",
    password: "",
  },
  isFetching: false,
  message: "",
};

const CLEAR_FORM = "CLEAR_FORM";
const CHANGE_PASSWORD = "CHANGE_PASSWORD_FIELD";
const CHANGE_EMAIL = "CHANGE_EMAIL_FIELD";
const SET_IS_FETCHING = "SET_IS_FETCHING";
const SET_MESSAGE = "SET_MESSAGE";

const authFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FORM:
      return {
        form: {
          email: "",
          password: "",
        },
        isFetching: false,
        message: "",
      };

    case CHANGE_PASSWORD:
      return { ...state, form: { ...state.form, password: action.password } };

    case CHANGE_EMAIL:
      return { ...state, form: { ...state.form, email: action.email } };

    case SET_IS_FETCHING:
      return { ...state, isFetching: action.isFetching };

    case SET_MESSAGE:
      return { ...state, message: action.message };

    default:
      return state;
  }
};

export const clearFormCreator = () => {
  return {
    type: CLEAR_FORM,
  };
};

export const changePasswordCreator = (password) => {
  return {
    type: CHANGE_PASSWORD,
    password,
  };
};

export const changeEmailCreator = (email) => {
  return {
    type: CHANGE_EMAIL,
    email,
  };
};

export const setIsFetchingCreator = (isFetching) => {
  return {
    type: SET_IS_FETCHING,
    isFetching,
  };
};

export const setMessage = (message) => {
  return {
    type: SET_MESSAGE,
    message,
  };
};

export const submitRegistration = (email, password) => (dispatch) => {
  dispatch(setIsFetchingCreator(true));
  return registration(email, password)
    .then((data) => {
      dispatch(clearFormCreator());
      dispatch(setIsFetchingCreator(false));
      return data;
    })
    .catch((error) => {
      dispatch(setIsFetchingCreator(false));
      const status = error.response.status;
      switch (status) {
        case 404:
          dispatch(setMessage("Сервер недоступен"));
          break;

        case 500:
          dispatch(setMessage("Ошибка сервера"));
          break;

        default:
          break;
      }
    });
};

export const forgotPassword = (email) => (dispatch) => {
  dispatch(setIsFetchingCreator(true));
  return forgotPasswordRequest(email)
    .then((data) => {
      console.log(data);
      dispatch(clearFormCreator());
      dispatch(setIsFetchingCreator(false));
      dispatch(
        setMessage("На указанную почту была отправлена ссылка для сброса пароля")
      );
    })
    .catch((error) => {
      dispatch(setIsFetchingCreator(false));
      console.log(error);
      const status = error.response.status;
      if (status === 400) {
        dispatch(setMessage("Email не зарегистрирован"));
      }
      if (status === 500) {
        dispatch(setMessage("Ошибка!"))
      }
    }); 
};

export const submitLogin = (email, password) => (dispatch) => {
  dispatch(setIsFetchingCreator(true));
  return loginRequest(email, password)
    .then((data) => {
      dispatch(clearFormCreator());
      dispatch(setIsFetchingCreator(false));
      dispatch(login(data.token, data.userId));
      return data;
    })
    .catch((error) => {
      dispatch(setIsFetchingCreator(false));
      const status = error.response.status;
      switch (status) {
        case 400:
          dispatch(setMessage("Неправильный логин или пароль"));
          break;

        case 404:
          dispatch(setMessage("Сервер недоступен"));
          break;

        case 500:
          dispatch(setMessage("Ошибка сервера"));
          break;

        default:
          break;
      }
    });
};

export default authFormReducer;
