import React from 'react'

const Document = (props) => {
  const { date, name, onDownloadButtonClick, onDeleteButtonClick } = props

  return (
    <div className="documents__document">
      <div className="documents__name">{name}</div>
      <div className="documents__date">{date}</div>
      <div className="documents__buttons">
        <button
          className="btn red waves-effect waves-light"
          onClick={onDeleteButtonClick}
        >
          Удалить
        </button>
        <button
          className="btn orange waves waves-effect waves-light"
          onClick={onDownloadButtonClick}
        >
          Скачать
        </button>
      </div>
    </div>
  )
}

export default Document
