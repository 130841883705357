import { createRecommendation, downloadFile } from '../api/api'
import { logout } from './authReducer'
import deepcopy from 'deepcopy'
import initialState from './recommendationFormInitialState'
import { prepareFormDataToSend } from '../utils/prepareFormDataToSend'
import dateToRussianFormat from '../utils/dateToRussianFormat'

const SET_SAVE = 'SET_SAVE'
const LOAD_SAVE = 'LOAD_SAVE'

const CHANGE_VALUE_ON_TOP_LEVEL = 'CHANGE_VALUE_ON_TOP_LEVEL'
const SET_SELECTED_BY_NAME_ON_TOP_LEVEL = 'SET_SELECTED_BY_NAME_ON_TOP_LEVEL'
const CLEAR_RECOMENDATION_FORM = 'CLEAR_RECOMENDATION_FORM'
const SET_RECOMMENDATION_FORM_MESSAGE = 'SET_RECOMMENDATION_FORM_MESSAGE'
const CHANGE_BIRTHDATE = 'CHANGE_BIRTHDATE'
const CHANGE_DATE_OF_FILLING = 'CHANGE_DATE_OF_FILLING'
const CHANGE_DATE_OF_ENROLLMENT = 'CHANGE_DATE_OF_ENROLLMENT'
const SHOW_EMPTY_FIELDS = 'SHOW_EMPTY_FIELDS'

const CHANGE_TASK_CHECKED_OPTION = 'CHANGE_TASK_CHECKED_OPTION'
const ADD_SET_TO_CHARACTERISTIC = 'ADD_SET_TO_CHARACTERISTIC'
const RETURN_TO_DEFAULT_SET = 'RETURN_TO_DEFAULT_SET'
const CHANGE_CHARACTERISTIC_IS_DONE = 'CHANGE_CHARACTERISTIC_IS_DONE'

const SET_CHECKED_ESTIMATE_OPTIONS = 'SET_CHECKED_ESTIMATE_OPTIONS'

const SET_CHECKED_SPECIALISTS = 'SET_CHECKED_SPECIALISTS'
const SET_NEED_TO_ADD_SPECIALISTS_OPTION = 'SET_NEED_TO_ADD_SPECIALISTS_OPTION'
const CHANGE_SPECIALISTS_OPTION_TO_ADD = 'CHANGE_SPECIALISTS_OPTION_TO_ADD'
const ADD_SPECIALISTS_OPTION = 'ADD_SPECIALISTS_OPTION'

const CHANGE_SCHEDULE_START_TIME = 'CHANGE_SCHEDULE_START_TIME'
const CHANGE_SCHEDULE_FINISH_TIME = 'CHANGE_SCHEDULE_FINISH_TIME'

const CHANGE_SPECIAL_TRAINING_CONDITIONS_ANOTHER =
  'CHANGE_SPECIAL_TRAINING_CONDITION_ANOTHER'

const SET_SURVEY_SELECTED = 'SET_SURVEY_SELECTED'
const CHANGE_SURVEY_ANOTHER = 'CHANGE_SURVEY_ANOTHER'

const recommendationFormReducer = (state = deepcopy(initialState), action) => {
  switch (action.type) {
    case CLEAR_RECOMENDATION_FORM:
      return deepcopy(initialState)

    case SET_SAVE:
      return {
        ...state,
        save: action.save,
      }

    case LOAD_SAVE:
      return action.formData

    case SET_RECOMMENDATION_FORM_MESSAGE:
      return {
        ...state,
        message: action.message,
      }

    case CHANGE_VALUE_ON_TOP_LEVEL:
      return {
        ...state,
        [action.name]: action.value,
      }

    case SET_SELECTED_BY_NAME_ON_TOP_LEVEL:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          selected: action.selected,
        },
      }

    case CHANGE_BIRTHDATE:
      return {
        ...state,
        birthDate: action.birthDate,
        age: action.age,
        basePeriod: action.period,
        characteristiсs: deepcopy(initialState.characteristiсs).map((c) => {
          return {
            ...c,
            setsToShow: [action.period],
            currentSet: action.period,
          }
        }),
        surveys: deepcopy(initialState.surveys),
      }

    case CHANGE_DATE_OF_ENROLLMENT:
      return {
        ...state,
        dateOfEnrollment: action.dateOfEnrollment,
      }

    case CHANGE_DATE_OF_FILLING:
      return {
        ...state,
        dateOfFilling: action.dateOfFilling,
      }

    case SHOW_EMPTY_FIELDS:
      return {
        ...state,
        emptyFieldsAreVisible: true,
      }

    case CHANGE_TASK_CHECKED_OPTION:
      return {
        ...state,
        characteristiсs: state.characteristiсs.map((c) => {
          if (c.id === action.characteristiсId) {
            return {
              ...c,
              score: c.score + (action.checked ? 1 : -1),
              sets: c.sets.map((s) => {
                if (s.period === action.period) {
                  return {
                    ...s,
                    score: s.score + (action.checked ? 1 : -1),
                    tasks: s.tasks.map((t) => {
                      if (t.id === action.taskId) {
                        return {
                          ...t,
                          checked: action.checked,
                        }
                      }
                      return t
                    }),
                  }
                }
                return s
              }),
            }
          }
          return c
        }),
      }

    case RETURN_TO_DEFAULT_SET:
      return {
        ...state,
        characteristiсs: state.characteristiсs.map((c) => {
          if (c.id === action.characteristiсId) {
            return {
              ...c,
              setsToShow: [action.basePeriod],
              currentSet: action.basePeriod,
              sets: c.sets.map((s) => {
                if (s.period !== action.basePeriod) {
                  return {
                    ...s,
                    score: 0,
                    tasks: s.tasks.map((t) => {
                      return { ...t, checked: false }
                    }),
                  }
                }
                return s
              }),
            }
          }
          return c
        }),
      }

    case CHANGE_CHARACTERISTIC_IS_DONE:
      return {
        ...state,
        characteristiсs: state.characteristiсs.map((c) => {
          if (c.id === action.characteristiсId) {
            return {
              ...c,
              done: action.done,
            }
          }
          return c
        }),
      }

    case ADD_SET_TO_CHARACTERISTIC:
      return {
        ...state,
        characteristiсs: state.characteristiсs.map((c) => {
          if (c.id === action.characteristiсId) {
            return {
              ...c,
              setsToShow: [...c.setsToShow, action.period],
              currentSet: action.period,
            }
          }
          return c
        }),
      }

    case SET_CHECKED_ESTIMATE_OPTIONS:
      return {
        ...state,
        healthStatus: {
          ...state.healthStatus,
          estimations: state.healthStatus.estimations.map((e) => {
            if (action.id === e.id) {
              return {
                ...e,
                selected: action.selected,
              }
            }
            return e
          }),
        },
      }

    case SET_CHECKED_SPECIALISTS:
      return {
        ...state,
        specialists: {
          ...state.specialists,
          selected: action.selected,
          schedules: action.selected.map((s) => {
            return {
              specialist: s,
              mo: {
                start: '',
                finish: '',
              },
              tu: {
                start: '',
                finish: '',
              },
              we: {
                start: '',
                finish: '',
              },
              th: {
                start: '',
                finish: '',
              },
              fr: {
                start: '',
                finish: '',
              },
            }
          }),
        },
      }

    case SET_NEED_TO_ADD_SPECIALISTS_OPTION:
      return {
        ...state,
        specialists: {
          ...state.specialists,
          needToAddOption: action.needToAddOption,
        },
      }

    case CHANGE_SPECIALISTS_OPTION_TO_ADD:
      return {
        ...state,
        specialists: {
          ...state.specialists,
          optionToAdd: action.optionToAdd,
        },
      }

    case ADD_SPECIALISTS_OPTION:
      return {
        ...state,
        specialists: {
          ...state.specialists,
          needToAddOption: false,
          optionToAdd: '',
          options: [...state.specialists.options, action.option],
          selected: [...state.specialists.selected, action.option],
          schedules: [
            ...state.specialists.schedules,
            {
              specialist: action.option,
              mo: {
                start: '',
                finish: '',
              },
              tu: {
                start: '',
                finish: '',
              },
              we: {
                start: '',
                finish: '',
              },
              th: {
                start: '',
                finish: '',
              },
              fr: {
                start: '',
                finish: '',
              },
            },
          ],
        },
      }

    case CHANGE_SCHEDULE_START_TIME:
      return {
        ...state,
        specialists: {
          ...state.specialists,
          schedules: state.specialists.schedules.map((s) => {
            if (action.specialist === s.specialist) {
              return {
                ...s,
                [action.day]: {
                  ...s[action.day],
                  start: action.start,
                },
              }
            }

            return s
          }),
        },
      }

    case CHANGE_SCHEDULE_FINISH_TIME:
      return {
        ...state,
        specialists: {
          ...state.specialists,
          schedules: state.specialists.schedules.map((s) => {
            if (action.specialist === s.specialist) {
              return {
                ...s,
                [action.day]: {
                  ...s[action.day],
                  finish: action.finish,
                },
              }
            }

            return s
          }),
        },
      }

    case CHANGE_SPECIAL_TRAINING_CONDITIONS_ANOTHER:
      return {
        ...state,
        specialTrainingConditions: {
          ...state.specialTrainingConditions,
          another: action.another,
        },
      }

    case SET_SURVEY_SELECTED:
      return {
        ...state,
        surveys: state.surveys.map((s) => {
          if (s.id === action.surveyId) {
            return {
              ...s,
              [action.name]: {
                ...s[action.name],
                selected: action.selected,
              },
            }
          }
          return s
        }),
      }

    case CHANGE_SURVEY_ANOTHER:
      return {
        ...state,
        surveys: state.surveys.map((s) => {
          if (s.id === action.surveyId) {
            return {
              ...s,
              [action.name]: {
                ...s[action.name],
                another: action.another,
              },
            }
          }
          return s
        }),
      }

    default:
      return state
  }
}

export const changeBirthDate = (birthDate) => {
  console.log(birthDate)
  const today = new Date()
  const months =
    (today.getFullYear() - birthDate.getFullYear()) * 12 +
    (today.getMonth() - birthDate.getMonth())
  const fullYears = Math.floor(months / 12)
  const period = fullYears + 1 > 4 ? 4 : fullYears + 1
  const obj = {
    type: CHANGE_BIRTHDATE,
    birthDate,
    age: months,
    period,
  }
  return obj
}

export const setRecommendationFormMessage = (message) => {
  return {
    type: SET_RECOMMENDATION_FORM_MESSAGE,
    message,
  }
}

export const setSelectedByName = (name, selected) => {
  return {
    type: SET_SELECTED_BY_NAME_ON_TOP_LEVEL,
    name,
    selected,
  }
}

export const changeValueByNameOnTopLevel = (name, value) => {
  return {
    type: CHANGE_VALUE_ON_TOP_LEVEL,
    name,
    value,
  }
}

export const changeDateOfFIlling = (dateOfFilling) => {
  return {
    type: CHANGE_DATE_OF_FILLING,
    dateOfFilling,
  }
}

export const changeDateOfEnrollment = (dateOfEnrollment) => {
  return {
    type: CHANGE_DATE_OF_ENROLLMENT,
    dateOfEnrollment,
  }
}

export const clearRecommendationForm = () => {
  return {
    type: CLEAR_RECOMENDATION_FORM,
  }
}

export const showEmptyFields = () => {
  return {
    type: SHOW_EMPTY_FIELDS,
  }
}

export const changeTaskChecked = (
  characteristiсId,
  period,
  taskId,
  checked,
) => {
  return {
    type: CHANGE_TASK_CHECKED_OPTION,
    characteristiсId,
    period,
    taskId,
    checked,
  }
}

export const addSetToCharacteristic = (characteristiсId, period) => {
  return {
    type: ADD_SET_TO_CHARACTERISTIC,
    characteristiсId,
    period,
  }
}

export const changeCharacteristicIsDone = (characteristiсId, done) => {
  return {
    type: CHANGE_CHARACTERISTIC_IS_DONE,
    characteristiсId,
    done,
  }
}

export const returnToDefaultSet = (characteristiсId, basePeriod) => {
  return { type: RETURN_TO_DEFAULT_SET, characteristiсId, basePeriod }
}

export const setSurveySelected = (surveyId, name, selected) => {
  return {
    type: SET_SURVEY_SELECTED,
    surveyId,
    name,
    selected,
  }
}

export const changeSurveyAnother = (surveyId, name, another) => {
  return {
    type: CHANGE_SURVEY_ANOTHER,
    surveyId,
    name,
    another,
  }
}

export const setCheckedEstimateOptions = (id, selected) => {
  return {
    type: SET_CHECKED_ESTIMATE_OPTIONS,
    id,
    selected,
  }
}

export const setCheckedSpecialists = (selected) => {
  return {
    type: SET_CHECKED_SPECIALISTS,
    selected,
  }
}

export const setNeedToAddSpecialist = (needToAddOption) => {
  return {
    type: SET_NEED_TO_ADD_SPECIALISTS_OPTION,
    needToAddOption,
  }
}

export const changeSpecialistOptionToAdd = (optionToAdd) => {
  return {
    type: CHANGE_SPECIALISTS_OPTION_TO_ADD,
    optionToAdd,
  }
}

export const addSpecialistsOption = (option) => {
  return { type: ADD_SPECIALISTS_OPTION, option }
}

export const changeScheduleStartTime = (specialist, day, start) => {
  return {
    type: CHANGE_SCHEDULE_START_TIME,
    specialist,
    day,
    start,
  }
}

export const changeScheduleFinishTime = (specialist, day, finish) => {
  return {
    type: CHANGE_SCHEDULE_FINISH_TIME,
    specialist,
    day,
    finish,
  }
}

export const changeSpecialTrainingConditionsAnother = (another) => {
  return {
    type: CHANGE_SPECIAL_TRAINING_CONDITIONS_ANOTHER,
    another,
  }
}

export const setSave = (save) => {
  return {
    type: SET_SAVE,
    save,
  }
}

export const loadSave = (formData) => {
  return {
    type: LOAD_SAVE,
    formData,
  }
}

export const submitRecomendationForm = (token, userId, formData) => (
  dispatch,
) => {
  let characteristiсsDone = true

  formData.characteristiсs.forEach((c) => {
    if (!c.done) {
      characteristiсsDone = false
    }
  })

  if (characteristiсsDone) {
    return createRecommendation(token, userId, prepareFormDataToSend(formData))
      .then((res) => {
        downloadFile(token, res.data.fileId, res.data.fileName)
        dispatch(clearRecommendationForm())
      })
      .catch((error) => {
        if (error.response.status === 403) {
          dispatch(logout())
        }
      })
  } else {
    dispatch(setRecommendationFormMessage('Не все характеристики заполнены!'))
  }
}

export const save = (formData) => (dispatch) => {
  localStorage.setItem('save', JSON.stringify(formData))
  dispatch(setSave(true))
}

export const load = () => (dispatch) => {
  const save = JSON.parse(localStorage.getItem('save'))
  dispatch(
    loadSave({
      ...save,
      birthDate: save.birthDate
        ? dateToRussianFormat(save.birthDate)
        : save.birthDate,
      dateOfEnrollment: save.dateOfEnrollment
        ? dateToRussianFormat(save.dateOfEnrollment)
        : save.dateOfEnrollment,
      dateOfFilling: save.dateOfFilling
        ? dateToRussianFormat(save.dateOfFilling)
        : save.dateOfFilling,
    }),
  )
}

export default recommendationFormReducer
