import React, { useEffect } from 'react'
import './CabinetPage.scss'
import Document from '../../components/Document/Document'
import HeaderContainer from '../../components/Header/HeaderContainer'
import Footer from '../../components/Footer/Footer'

const CabinetPage = (props) => {
  const { getFiles, downloadFile, files, token, userId, deleteFile } = props

  useEffect(() => {
    getFiles(token, userId)
  }, [getFiles, token, userId])

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const filesElements = files.length > 0 ? files.map((f) => {
    return (
      <Document
        key={f.id}
        name={f.fileName.split('_', 2).join(' ')}
        date={new Date(f.dateCreated).toLocaleString('ru', dateOptions)}
        onDownloadButtonClick={() => {
          downloadFile(token, f.id, f.fileName)
        }}
        onDeleteButtonClick={() => {
          deleteFile(token, f.id, userId)
        }}
      />
    )
  }) : <h4 >Документы не найдены</h4>

  return (
    <>
      <HeaderContainer path={props.location.pathname} />

      <div className="container" style={{ margin: '0 auto' }}>
        <h1 className="documents__title">Личный кабинет</h1>
        <div className="documents__wrapper">
          <div className="documents">{filesElements}</div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default CabinetPage
