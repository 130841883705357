import React, { useState } from "react";
import Spoiler from "../../../Spoiler/Spoiler";

const SetSpoilers = (props) => {
  const { characteristiсId, period, score, currentSet, basePeriod, targets, content, previousTargets, previousContent } = props;

  const [isContentSpoilerExpanded, setContentSpoilerExpanded] = useState(false);
  const [isTargetsSpoilerExpanded, setTargetsSpoilerExpanded] = useState(false);
  const [isBadMessageExpanded, setBadMessageVisability] = useState(false);
  const [isGoodMessageExpanded, setGoodMessageVisability] = useState(false);
  const id = `${characteristiсId}-${period}`;

  const isContentSpoilerVisible =
    (score === 2 && period === currentSet && basePeriod === period) || period > basePeriod || (period < basePeriod && score > 1);
  const isBadMessageSpoilerVisible = currentSet === 1 && period === 1 && score < 2;
  const isGoodMessageVisible = score === 3 && period === 4 && basePeriod === 4;

  const createSpoilerTargets = () => {
    {
      if (score >= 2) {
        return targets.map((t, i) => {
          return (
            <p className="grey-text set__paragraph" key={i}>
              {t}
            </p>
          );
        });
      }

      if (score < 2 && period > basePeriod) {
        return previousTargets.map((t, i) => {
          return (
            <p className="grey-text set__paragraph" key={i}>
              {t}
            </p>
          );
        });
      }
    }
  };

  const createSpoilerContent = () => {
    {
      if (score >= 2) {
        return content.map((c, i) => {
          return (
            <p className="grey-text set__paragraph" key={i}>
              {c}
            </p>
          );
        });
      }

      if (score < 2 && period > basePeriod) {
        return previousContent.map((c, i) => {
          return (
            <p className="grey-text set__paragraph" key={i}>
              {c}
            </p>
          );
        });
      }
    }
  };

  return (
    <div className="col s12 set__spoilers">
      {isContentSpoilerVisible ? (
        <>
          <Spoiler
            expanded={isContentSpoilerExpanded}
            onClick={() => {
              setContentSpoilerExpanded(!isContentSpoilerExpanded);
            }}
            id={"targets" + id}
            summary="Содержание"
          >
            <div>{createSpoilerContent()}</div>
          </Spoiler>

          {isContentSpoilerExpanded ? (
            <Spoiler
              expanded={isTargetsSpoilerExpanded}
              onClick={() => {
                setTargetsSpoilerExpanded(!isTargetsSpoilerExpanded);
              }}
              summary={"Психологические достижения для оценки результативности обучения по данной образовательной области"}
              id={"content" + id}
            >
              <div>{createSpoilerTargets()}</div>
            </Spoiler>
          ) : null}
        </>
      ) : null}
      {isBadMessageSpoilerVisible ? (
        <Spoiler
          expanded={isBadMessageExpanded}
          onClick={() => {
            setBadMessageVisability(!isBadMessageExpanded);
          }}
          id={"badMessage" + id}
          summary="Действия специалиста при выборе содержания СИПР для данной образовательной области"
        >
          <div>
            <p className="grey-text set__paragraph">
              - следует провести обследование по методике «Диагностика психической активности младенцев», <a href="https://znanium.com/read?id=425049">Метод.пособие.</a> – М., 2017. – 68 с.
            </p>
            <p className="grey-text set__paragraph">
              - на медико-психолого-педагогическом консилиуме принять решение о содержании, организации и режиме индивидуальной
              коррекционно-педагогической помощи.
            </p>
          </div>
        </Spoiler>
      ) : null}

      {isGoodMessageVisible ? (
        <Spoiler
          expanded={isGoodMessageExpanded}
          onClick={() => {
            setGoodMessageVisability(!isGoodMessageExpanded);
          }}
          summary="Действия специалиста при выборе содержания СИПР для данной образовательной области"
        >
          <div>
            <p className="grey-text set__paragraph">
              - необходимо направить ребенка на психолого-педагогическую комиссию для выбора ПРАООП и уточнения содержания обучения.
            </p>
          </div>
        </Spoiler>
      ) : null}

      {isContentSpoilerVisible ? (
        <div>
          <p style={{ marginTop: "20px" }}>
            При достижении ребенком не менее 50% целевых ориентиров и постоянном использовании психологических достижений в самостоятельной активности
            следует провести психолого-педагогическое обследование и оценить психологические достижения, соответствующие уровню предметных действий.
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default SetSpoilers;
