import React, { useState } from 'react'
import Set from './Set/Set'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import './Characteristic.scss'

const Characteristiс = (props) => {
  const {
    id,
    title,
    setsToShow,
    sets,
    changeTaskChecked,
    addSetToCharacteristic,
    basePeriod,
    currentSet,
    returnToDefaultSet,
    done,
    changeCharacteristicIsDone,
  } = props
  const [expand, setExpand] = useState(false)
  const toggleExpand = () => setExpand(!expand)
  const expandIcon = expand ? (
    <ExpandLessIcon className="orange-text" fontSize="large" />
  ) : (
    <ExpandMoreIcon className="orange-text" fontSize="large" />
  )

  const setsElements = sets.map((s, i, sets) => {
    const previousContent = i - 1 >= 0 ? sets[i - 1].content : false
    const previousTargets = i - 1 >= 0 ? sets[i - 1].targets : false

    if (setsToShow.includes(s.period)) {
      return (
        <>
          <Set
            {...s}
            key={i}
            characteristiсId={id}
            changeTaskChecked={changeTaskChecked}
            addSetToCharacteristic={addSetToCharacteristic}
            isLastSet={!setsToShow.includes(s.period + 1)}
            basePeriod={basePeriod}
            currentSet={currentSet}
            returnToDefaultSet={returnToDefaultSet}
            done={done}
            changeCharacteristicIsDone={changeCharacteristicIsDone}
            previousContent={previousContent}
            previousTargets={previousTargets}
          />
        </>
      )
    }
    return null
  })

  return (
    <div className={`characteristic`}>
      <h4 className="characteristic__title">
        <span>{title}</span>
        <div className="characteristic__buttons">
          <button
            type="button"
            onClick={toggleExpand}
            className="characteristic__button"
          >
            {expandIcon}
          </button>
        </div>
      </h4>

      {expand ? (
        <div className="row">
          {currentSet >= basePeriod ? setsElements : setsElements.reverse()}
        </div>
      ) : null}
    </div>
  )
}

export default Characteristiс
