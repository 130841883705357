import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'

const Spoiler = (props) => {
  const { expanded, id, onClick, children, summary } = props

  return (
    <Accordion expanded={expanded} onChange={onClick} className="#e8f5e9 green lighten-5">
      <AccordionSummary
        aria-controls={'spoiler' + id}
        id={'header' + id}
        expandIcon={<ExpandMoreIcon />} 
        style={{fontSize: "1.2rem"}}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export default Spoiler
