import dateToRussianFormat from './dateToRussianFormat'

function _chooseDocumentOutputCode(
  basePeriod,
  currentSet,
  currentSetScore,
  previousSet,
) {
  if (currentSetScore === 3 && currentSet === 4 && basePeriod === 4) {
    return 'GOOD'
  } else if (currentSet === 1 && currentSet === 1 && currentSetScore < 2) {
    return 'BAD'
  } else if (previousSet) {
    console.log(previousSet, 'here')
    return currentSet - 1
  } else if (
    (currentSetScore === 2 && basePeriod === currentSet) ||
    currentSet > basePeriod ||
    (currentSet < basePeriod && currentSetScore > 1)
  ) {
    return currentSet
  }

  throw new Error('Ошибка выбора вставки в документ')
}

function _preparecharacteristiсsToSend(basePeriod, characteristiсs) {
  const output = characteristiсs.map((c) => {
    const { title, sets, currentSet, score } = c

    debugger

    const currentSetScore = sets.find((s) => {
      return s.period === currentSet
    }).score

    const previousSet = currentSetScore < 2 && currentSet > basePeriod

    return {
      title,
      score,
      documentOutputCode: _chooseDocumentOutputCode(
        basePeriod,
        currentSet,
        currentSetScore,
        previousSet,
      ),
    }
  })

  return output
}

export const prepareFormDataToSend = (formData) => {
  let estimations = []
  formData.healthStatus.estimations.forEach((e) => {
    if (e.selected.length > 0) {
      estimations.push({
        title: e.title,
        text: e.selected.join('. '),
      })
    }
  })
  const specialTrainingConditions = formData.specialTrainingConditions.selected.map(
    (stc) => {
      if (stc === 'Другое') {
        return formData.specialTrainingConditions.another
      }
      return stc
    },
  )

  const surveys = formData.surveys.map((s) => ({
    title: s.title,
    psychologicalAchievements:
      s.psychologicalAchievements.selected === 'Другое'
        ? s.psychologicalAchievements.another
        : s.psychologicalAchievements.selected,

    pathologicalBehavior: s.pathologicalBehavior.selected.reduce(
      (previousValue, currentValue) => {
        if (currentValue === 'Другое') {
          return previousValue + s.pathologicalBehavior.another + '. '
        }
        return previousValue + currentValue + '. '
      },
      '',
    ),
  }))

  const characteristiсs = _preparecharacteristiсsToSend(
    formData.basePeriod,
    formData.characteristiсs,
  )

  return {
    ...formData,

    dateOfEnrollment: dateToRussianFormat(formData.dateOfEnrollment),
    dateOfFilling: dateToRussianFormat(formData.dateOfFilling),
    birthDate: dateToRussianFormat(formData.birthDate),

    dynamics: formData.dynamics.selected,
    socialCategory: formData.socialCategory.selected.join('. '),
    estimations,
    healthStatus: formData.healthStatus.selected,
    specialists: formData.specialists.schedules,
    technicalEquipment: formData.technicalEquipment.selected,
    specialTrainingConditions,
    familyStatus: formData.familyStatus.selected,
    parentsEducation: formData.parentsEducation.selected,
    livingConditions: formData.livingConditions.selected,
    financialSituation: formData.financialSituation.selected,
    recommendationsImplementation:
      formData.recommendationsImplementation.selected,
    participationInEducation: formData.participationInEducation.selected,
    communicationPractice: formData.communicationPractice.selected,
    educationGroupCondition: formData.educationGroupCondition.selected,
    trainingMode: formData.trainingMode.selected,
    trainingOrganiztion: formData.trainingOrganiztion.selected,
    surveys,
    characteristiсs,
  }
}
