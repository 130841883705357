import "./AuthPage.scss";

import React from "react";
import Login from "../../components/Login/Login";
import Registration from "../../components/Registration/Registration";
import { Route } from "react-router-dom";
import HeaderContainer from "../../components/Header/HeaderContainer";
import Footer from "../../components/Footer/Footer";
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword";

const AuthPage = (props) => {
  const {
    password,
    email,
    isFetching,
    changeEmail,
    changePassword,
    submitLogin,
    submitRegistration,
    message,
    forgotPassword,
    clearForm
  } = props;

  return (
    <>
      <HeaderContainer />
      <div className="authPage">
        <Route path="/login">
          <Login
            password={password}
            email={email}
            isFetching={isFetching}
            changePassword={changePassword}
            changeEmail={changeEmail}
            submitLogin={submitLogin}
            message={message}
            clear={clearForm}
          />
        </Route>

        <Route path="/registration">
          <Registration
            password={password}
            email={email}
            isFetching={isFetching}
            changePassword={changePassword}
            changeEmail={changeEmail}
            submitRegistration={submitRegistration}
            message={message}
            clear={clearForm}
          />
        </Route>

        <Route path="/forgot-password">
          <ForgotPassword
            email={email}
            isFetching={isFetching}
            changeEmail={changeEmail}
            message={message}
            submitForgotPasswordForm={forgotPassword}
            clear={clearForm}
          />
        </Route>
      </div>
      <Footer/>
    </>
  );
};

export default AuthPage;
