import React from "react";
import { Link } from "react-router-dom";

const ForgotPassword = (props) => {
  const {
    email,
    clear,
    isFetching,
    changeEmail,
    submitForgotPasswordForm,
    message
  } = props;

  return (
    <div className="row">
      <h3 className="col s12">Восстановление пароля</h3>
      <form
        className="col s12"
        onSubmit={(event) => {
          event.preventDefault();
          submitForgotPasswordForm(email);
          console.log("reset");
        }}
      >
        <div className="row">
          <div className="input-field col s12">
            <input
              id="email"
              name="email"
              type="email"
              className="validate"
              value={email}
              onChange={(event) => changeEmail(event.target.value.toLowerCase())}
              required
            />
            <label htmlFor="email">Email</label>
            <span className="helper-text" data-error="Некорректный email" data-success="">
              Введите email
            </span>
          </div>
        </div>
        <div className="row">
          <span className="red-text">{message}</span>
        </div>
        <div className="row">
          <button
            className="btn waves-effect orange waves-light authButton"
            type="submit"
            disabled={isFetching}
          >
            Восстановить
          </button>
          <Link onClick={clear} to="/login" className="btn-outline btn-reg orange-text">
            Войти
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
