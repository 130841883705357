import React from 'react'
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core'
import menuProps from '../../../data/menuProps'

const MultipleSelect = (props) => {
  const { selected, onChange, options, id, name, label, children } = props
  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        className="select"
        name={name}
        labelId={`${id}-label`}
        label={label}
        id={`${id}`}
        multiple
        value={selected}
        onChange={onChange}
        MenuProps={menuProps}
        renderValue={(selected) => {
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              {selected.map((s, i) => {
                return <Chip key={i} label={s}></Chip>
              })}
            </Box>
          )
        }}
      >
        {children}
        {options
          ? options.map((o) => (
              <MenuItem key={o} value={o}>
                <ListItemIcon>
                  <Checkbox
                    className="orange-text"
                    checked={selected.includes(o)}
                  />
                </ListItemIcon>
                <ListItemText className="green-text" primary={o} />
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  )
}

export default MultipleSelect
