import React from 'react'
import Task from './Task/Task'
import './Set.scss'
import { periodToAgeGroup } from '../../../../utils/periodToAgeGroup'
import SetButtons from './SetButtons/SetButtons'
import SetSpoilers from './SetSpoilers/SetSpoilers'

const Set = (props) => {
  const {
    period,
    characteristiсId,
    score,
    isLastSet,
    changeTaskChecked,
    tasks,
    addSetToCharacteristic,
    basePeriod,
    currentSet,
    returnToDefaultSet,
    targets,
    content,
    done,
    changeCharacteristicIsDone,
    previousContent,
    previousTargets
  } = props

  const nextPeriodButtonHandler = () => {
    addSetToCharacteristic(characteristiсId, period + 1)
  }
  
  const previousPeriodButtonHandler = () => {
    addSetToCharacteristic(characteristiсId, period - 1)
  }

  const cancelButtonHandler = () => {
    returnToDefaultSet(characteristiсId, basePeriod)
  }

  const doneButtonHandler = () => {
    changeCharacteristicIsDone(characteristiсId, !done)
  }

  const tasksElemets = tasks.map((t) => {
    return (
      <Task
        {...t}
        key={t.id}
        period={period}
        characteristiсId={characteristiсId}
        changeTaskChecked={changeTaskChecked}
        currentSet={currentSet}
      />
    )
  })

  return (
    <div className="set">
      {!done ? (
        <>
          <h5 className="set__title">
            {periodToAgeGroup(period)}
          </h5>
          {tasksElemets}
        </>
      ) : null}

      <SetSpoilers
        characteristiсId={characteristiсId}
        period={period}
        score={score}
        currentSet={currentSet}
        basePeriod={basePeriod}
        targets={targets}
        content={content}
        previousTargets={previousTargets}
        previousContent={previousContent}
      />

      <SetButtons
        onNextPeriodClick={nextPeriodButtonHandler}
        onCancelClick={cancelButtonHandler}
        onPreviousClick={previousPeriodButtonHandler}
        onDoneButtonClick={doneButtonHandler}
        period={period}
        currentSet={currentSet}
        basePeriod={basePeriod}
        isLastSet={isLastSet}
        score={score}
        done={done}
      ></SetButtons>
    </div>
  )
}

export default Set
