import {
  changeEmailCreator,
  changePasswordCreator,
  setIsFetchingCreator,
  submitRegistration,
  submitLogin,
  forgotPassword,
  clearFormCreator
} from "../../redux/authFormReducer";
import AuthPage from "./AuthPage";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  isFetching: state.authForm.isFetching,
  email: state.authForm.form.email,
  password: state.authForm.form.password,
  message: state.authForm.message,
});

const mapDispatchToProps = {
  changePassword: changePasswordCreator,
  changeEmail: changeEmailCreator,
  setIsFetching: setIsFetchingCreator,
  clearForm: clearFormCreator,
  submitRegistration,
  submitLogin,
  forgotPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
