import { Input } from '@material-ui/core'
import './Schedule.scss'

const Schedule = (props) => {
  const {
    specialist,
    mo,
    tu,
    we,
    th,
    fr,
    changeScheduleStartTime,
    changeScheduleFinishTime,
  } = props

  const startHandler = (event) => {
    changeScheduleStartTime(specialist, event.target.name, event.target.value)
  }
  const finishHandler = (event) => {
    changeScheduleFinishTime(specialist, event.target.name, event.target.value)
  }

  console.log(props)
  return (
    <div className="schedule">
      <h6 className="schedule__title">{specialist}</h6>

      <div className="schedule__wrapper">
        <div className="schedule__day">
          <p>ПН</p>
          <div className="schedule__input-wrapper">
            <span>Начало занятия</span>
            <Input
              className="schedule__timepicker"
              type="time"
              value={mo.start}
              onChange={startHandler}
              name="mo"
            />
            <span>Конец занятия</span>
            <Input
              className="schedule__timepicker"
              type="time"
              value={mo.finish}
              onChange={finishHandler}
              name="mo"
            />
          </div>
        </div>

        <div className="schedule__day">
          <p>ВТ</p>
          <div className="schedule__input-wrapper">
            <span>Начало занятия</span>
            <Input
              className="schedule__timepicker"
              type="time"
              value={tu.start}
              onChange={startHandler}
              name="tu"
            />
            <span>Конец занятия</span>
            <Input
              className="schedule__timepicker"
              type="time"
              value={tu.finish}
              onChange={finishHandler}
              name="tu"
            />
          </div>
        </div>

        <div className="schedule__day">
          <p>СР</p>
          <div className="schedule__input-wrapper">
            <span>Начало занятия</span>
            <Input
              className="schedule__timepicker"
              type="time"
              value={we.start}
              onChange={startHandler}
              name="we"
            />
            <span>Конец занятия</span>
            <Input
              className="schedule__timepicker"
              type="time"
              value={we.finish}
              onChange={finishHandler}
              name="we"
            />
          </div>
        </div>

        <div className="schedule__day">
          <p>ЧТ</p>
          <div className="schedule__input-wrapper">
            <span>Начало занятия</span>
            <Input
              className="schedule__timepicker"
              type="time"
              value={th.start}
              onChange={startHandler}
              name="th"
            />
            <span>Конец занятия</span>
            <Input
              className="schedule__timepicker"
              type="time"
              value={th.finish}
              onChange={finishHandler}
              name="th"
            />
          </div>
        </div>

        <div className="schedule__day">
          <p>ПТ</p>
          <div className="schedule__input-wrapper">
            <span>Начало занятия</span>
            <Input
              className="schedule__timepicker"
              type="time"
              value={fr.start}
              onChange={startHandler}
              name="fr"
            />
            <span>Конец занятия</span>
            <Input
              className="schedule__timepicker"
              type="time"
              value={fr.finish}
              onChange={finishHandler}
              name="fr"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Schedule
