import { connect } from 'react-redux'
import { getFiles, downloadFile, deleteFile } from '../../redux/cabinetReducer'
import CabinetPage from './CabinetPage'

const mapStateToProps = (state) => ({
  files: state.cabinet.files,
  token: state.auth.token,
  userId: state.auth.userId,
})

const mapDispatchToProps = {
  getFiles,
  downloadFile,
  deleteFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(CabinetPage)
