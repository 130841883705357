export const periodToAgeGroup = (period) => {
  switch (period) {
    case 1:
      return 'Набор для оценки ориентировочно-поисковой активности.'

    case 2:
      return 'Набор для оценки предметных действий.'

    case 3:
      return 'Набор для оценки предметной деятельности.'

    case 4:
      return 'Набор для оценки познавательной деятельности.'

    default:
      return `Период # ${period}`
  }
}