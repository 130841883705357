import React from 'react'
import './Footer.scss'

const Footer = () => {
  return (
    <footer className="footer">
      <div
        className="footer__container container"
        style={{ margin: '0 auto !important' }}
      >
        <div className="row">
          <div className="col s12 m6 footer__col1">
            <h5 className="footer__title">
              Номер регистрации (свидетельства):
            </h5>
            <p className="footer__text">2022612427</p>
            <h5 className="footer__title">Правообладатель:</h5>
            <p className="footer__text">
              Федеральное государственное бюджетное научное учреждение «Институт
              коррекционной педагогики Российской академии образования» (ФГБНУ
              «ИКП РАО»)
            </p>
          </div>

          <div className="col s12 m6 footer__col2">
            <h5 className="footer__title">Авторы:</h5>
            <p>Лазуренко Светлана Борисовна,</p>
            <p className="footer__text">Павлова Наталья Николаевна,</p>
            <p className="footer__text">Соловьева Татьяна Александровна,</p>
            <p className="footer__text">Соловьев Дмитрий Александрович,</p>
            <p className="footer__text">Золотарев Леонид Александрович</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
