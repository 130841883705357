import React from 'react'
import HeaderContainer from '../../components/Header/HeaderContainer'
import Info from '../../components/Info/Info'
import RecommendationFormContainer from '../../components/RecommendationForm/RecommendationFormContainer'
import Footer from '../../components/Footer/Footer'

const MainPage = () => {
  return (
    <>
      <main>
        <HeaderContainer />

        <Info />
        <div className="container" style={{ margin: '0 auto' }}>
          <RecommendationFormContainer />
        </div>
      </main>
      <Footer />
    </>
  )
}

export default MainPage
