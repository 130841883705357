import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import authFormReducer from "./authFormReducer";
import cabinetReducer from "./cabinetReducer";
import recommendationFormReducer from "./recommendationFormReducer";
import authReducer from "./authReducer";

const reducers = combineReducers({
  auth: authReducer,
  authForm: authFormReducer,
  recommendation: recommendationFormReducer,
  cabinet: cabinetReducer,
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
