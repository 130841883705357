import React, { useEffect } from 'react'
import M from 'materialize-css'

const Datepicker = (props) => {
  const { date, label, selectHandler, maxDate, minDate, id } = props

  useEffect(() => {
    const datepicker = document.querySelector('#datepicker' + id)
    M.Datepicker.init(datepicker, {
      maxDate: maxDate ? maxDate : null,
      minDate: minDate ? minDate : null,
      onSelect: (date) => {
        const instance = M.Datepicker.getInstance(datepicker)
        instance.close()
        selectHandler(date)
      },
      format: 'dd.mm.yyyy',
      defaultDate: date,
      firstDay: 1,
      setDefaultDate: date,
      i18n: {
        cancel: 'Отменить',
        clear: 'Очистить',
        done: 'OK',
        close: 'Закрыть',
        today: 'Hoy',
        closeOnSelect: false,
        selectMonths: true,
        previousMonth: '<',
        nextMonth: '>',
        months: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',
        ],
        monthsShort: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',
        ],
        firstDay: true,
        weekdays: [
          'Воскресенье',
          'Понедельник',
          'Вторник',
          'Среда',
          'Четверг',
          'Пятница',
          'Суббота',
        ],
        weekdaysShort: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
        weekdaysAbbrev: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
      },
    })
  }, [props])

  return (
    <>
      <input
        readOnly
        required
        id={`datepicker${id}`}
        value={date}
        className="datepicker"
      />
      <label htmlFor="datepicker" className="active">
        {label}
      </label>
    </>
  )
}

export default Datepicker
