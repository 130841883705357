import React from 'react'

const SetButtons = (props) => {
  const {
    period,
    currentSet,
    basePeriod,
    isLastSet,
    score,
    onNextPeriodClick,
    onCancelClick,
    onPreviousClick,
    onDoneButtonClick,
    done,
  } = props

  const isCancelButtonVisible = period !== currentSet && period === basePeriod
  const isNextButtonVisible =
    score === 3 && period < 4 && isLastSet && basePeriod === period
  const isPreviousButtonVisible =
    score < 2 && period > 1 && period === currentSet && period <= basePeriod
  const isDoneButtonVisible =
    (period === currentSet && period > basePeriod) ||
    (period === currentSet && (period < basePeriod) & (score > 1)) ||
    (period === currentSet && period === basePeriod && score === 2) ||
    (period === currentSet && period === 1 && score !== 3) ||
    (period === currentSet && done) ||
    (period === currentSet && period === 4 && score === 3)
  return (
    <div className="set__buttons col s12">
      {!done ? (
        <>
          {isNextButtonVisible ? (
            <button
              type="button"
              onClick={onNextPeriodClick}
              className="btn orange set__button"
            >
              Показать следующий набор
            </button>
          ) : null}

          {isCancelButtonVisible ? (
            <button
              type="button"
              className="btn orange set__button"
              onClick={onCancelClick}
            >
              Отмена
            </button>
          ) : null}

          {isPreviousButtonVisible ? (
            <button
              type="button"
              className="btn orange set__button"
              onClick={onPreviousClick}
            >
              Показать предыдущий набор
            </button>
          ) : null}
        </>
      ) : null}

      {isDoneButtonVisible ? (
        <button
          onClick={onDoneButtonClick}
          type="button"
          className="btn orange set__button"
        >
          {done ? 'Редактировать' : 'Готово'}
        </button>
      ) : null}
    </div>
  )
}

export default SetButtons
