import axios from "axios";

const baseURL = "/api";

const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const registration = (email, password) => {
  return api.post("/auth/registration", { email, password }).then((res) => res.data);
};

export const login = (email, password) => {
  return api.post("/auth/login", { email, password }).then((res) => res.data);
};

export const forgotPassword = (email) => {
  return api.post("/auth/forgot-password", { email }).then((res) => res.data);
};

export const createRecommendation = (token, userId, formData) => {
  return api.post(
    `/files/create/${userId}`,
    { formData },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export const downloadFile = (token, fileId, fileName = "document.docx") => {
  api
    .get("/files/download/" + fileId, {
      responseType: "blob",
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};

export const deleteFile = (token, fileId) => {
  return api.delete("/files/" + fileId, {
    headers: {
      Authorization: token,
    },
  });
};

export const getFileInfo = (token, userId) => {
  return api.get(`/files/info/${userId}`, {
    headers: {
      Authorization: token,
    },
  });
};
