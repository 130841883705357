import React from "react";
import Spoiler from "../Spoiler/Spoiler";

const FamilyResponsibilityPreview = (props) => {
  const { communicationPractice, participationInEducation, recommendationsImplementation } = props;

  const isVisible =
    communicationPractice && participationInEducation && recommendationsImplementation;

  function choosefamilyResponsibility() {
    const answers = [];
    switch (recommendationsImplementation) {
      case "Регулярное":
        answers.push("A");
        break;
      case "Периодическое":
        answers.push("B");
        break;
      default:
        answers.push("C");
        break;
    }

    switch (participationInEducation) {
      case "Высокая":
        answers.push("A");
        break;
      case "Средняя":
        answers.push("B");
        break;
      default:
        answers.push("C");
        break;
    }

    switch (communicationPractice) {
      case "Регулярная":
        answers.push("A");
        break;
      case "Нерегулярная":
        answers.push("B");
        break;
      default:
        answers.push("C");
        break;
    }

    if (answers.includes("C")) {
      return (
        <Spoiler summary="Низкая приверженность рекомендациям">
          <div>
            <p>
              • Формирование педагогической компетентности родителей в вопросах укрепления здоровья,
              воспитания и обучения ребенка с ТМНР (тематические семинары, вебинары, беседы)
            </p>
            <p>
              • Формирование представлений об особенностях развития ребенка, навыков и умений
              конструктивного взаимодействия в системе родитель-ребенок с ТМНР посредством участия
              взрослых в психолого-педагогических коррекционных мероприятий.
            </p>
            <p>
              • Постоянное обучение и контроль за качеством выполнения родителями основных
              коррекционно-педагогических, а также специальных психологических методов и способов,
              воспитательных приемов работы с детьми с ТМНР
            </p>
            <p>
              • Включение родителей во внеклассные мероприятия и совместную с ребенком социальную
              активность в условиях образовательных учреждений
            </p>
            <p>• Мониторинг жестокого обращения с ребенком в семье</p>
            <p>• Систематическая медико-психолого-социальная поддержка семьи</p>

            <h6>Литературные источники</h6>
            <p>
              1. Лазуренко С.Б., Павлова Н.Н. Организация надомного обучения дошкольников с тяжелыми
              множественными нарушениями развития (ТМНР). Москва: ИНФРА-М, 2021.71с.
            </p>
            <p>
              2. Ткачева В.В. Технологии психологической помощи семьям детей с отклонениями в
              развитии. М.: АСТ; Астрель,2007. 318 с.
            </p>
            <p>
              3. Свиридова Т.В., Лазуренко С.Б., Ртищева М.С., Герасимова А.М., Павлова Н.Н.
              Медико-социальные критерии диагностики жестокого обращения с хронически больными
              детьми в семье. Российский педиатрический журнал. 2018; 21(4): 216-220. DOI:
              <a
                href="http://dx.doi.org/10.18821/1560-9561-2018-21-4-216-220"
                target="_blank"
                rel="noreferrer"
              >
                http://dx.doi.org/10.18821/1560-9561-2018-21-4-216-220
              </a>
              .
            </p>
          </div>
        </Spoiler>
      );
    }

    if (answers.includes("B")) {
      return (
        <Spoiler summary="Средняя приверженность рекомендациям">
          <div>
            <p>
              • Систематическое психолого-педагогическое просвещение и формирование мотивации у
              родителей (лиц их замещающих), других членов семей (близких третьего возраста,
              сиблингов), непосредственно участвующих в его воспитании к сотрудничеству со
              специалистами психолого-педагогического профиля.
            </p>
            <p>
              • Регулярное обучение родителей применению коррекционно-педагогических, а также
              специальных психологических методов и способов, воспитательных приемов работы с детьми
              с ТМНР посредством проведения совместных психолого-педагогических коррекционных
              мероприятий в образовательной организации и на дому.
            </p>
            <p>
              • Психолого-педагогическое сопровождение семьи (помощь в преодолении тяжелых и
              длительных переживаний, стресса, связанных с проблемами психофизического развития
              ребенка, оптимизация детско-родительских отношений и внутрисемейного
              функционирования);
            </p>
            <p>
              • Индивидуальный подбор графика встреч с целью контроля правильности выполнения
              рекомендаций специалистов
            </p>

            <h6>Литературные источники</h6>

            <p>
              1. Лазуренко С.Б., Павлова Н.Н. Организация надомного обучения дошкольников с тяжелыми
              множественными нарушениями развития (ТМНР). Москва: ИНФРА-М, 2021.71с.
            </p>
            <p>
              2. Ткачёва В.В. Семья ребенка с ограниченными возможностями здоровья: диагностика и
              консультирование. М.: Национальный книжный центр, 2014. 160 с.
            </p>
            <p>
              3. Шипицына Л.М. «Необучаемый» ребенок в семье и обществе. Социализация детей с
              нарушением интеллекта. СПб.: Речь, 2005.477с.
            </p>
          </div>
        </Spoiler>
      );
    }

    return (
      <Spoiler summary="Высокая приверженность рекомендациям">
        <div>
          <p>
            • Психолого-педагогическое просвещение и формирование психолого-педагогической
            компетентности родителей (лиц их замещающих), других членов семей (близких третьего
            возраста, сиблингов), непосредственно участвующих в его воспитании
          </p>
          <p>
            • Обучение родителей организации практической деятельности ребенка, знакомство с
            основами построения досуга, оптимальными формами межличностного взаимодействия.
          </p>
          <p>
            • Консультирование по вопросам подходящего ребенку режима дня, питания и воспитания.
          </p>
          <p>
            • Предупреждение психофизических перегрузок, развития у родителей ребенка с ТМНР
            состояния дистресса (сеансы релаксации в сенсорной комнате, группа взаимной поддержки,
            краткосрочное консультирование)
          </p>
          <p>
            • Информационная поддержка специалистами психолого-педагогического профиля (по мере
            необходимости)
          </p>
          <h6>Литературные источники</h6>
          <p>
            1. Арламова Е.Н., Царев А.М. Сотрудничество семьи и специалистов в процессе обучения и
            воспитания детей с тяжелыми множественными нарушениями развития в условиях
            образовательной организации//Воспитание и обучение детей с нарушениями развития 2017.7.
            С.57-62
          </p>
          <p>
            2. Лазуренко С.Б., Павлова Н.Н. Организация надомного обучения дошкольников с тяжелыми
            множественными нарушениями развития (ТМНР). Москва: ИНФРА-М, 2021.71с.
          </p>
        </div>
      </Spoiler>
    );
  }

  return <>{!isVisible ? null : choosefamilyResponsibility()}</>;
};

export default FamilyResponsibilityPreview;
