import React, { useEffect } from "react";
import M from "materialize-css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const Registration = (props) => {
  const {
    submitRegistration,
    email,
    password,
    clear,
    isFetching,
    changeEmail,
    changePassword,
    message,
  } = props;

  useEffect(() => {
    M.AutoInit();
  }, []);

  const history = useHistory();

  return (
    <div className="row">
      <h3 className="col s12"> Регистрация</h3>
      <form
        className="col s12"
        onSubmit={(event) => {
          event.preventDefault();
          submitRegistration(email, password).then(() => {
            history.push("/login");
          });
        }}
      >
        <div className="row">
          <div className="input-field col s12">
            <input
              id="email"
              name="email"
              type="email"
              className="validate"
              value={props.email}
              onChange={(event) => props.changeEmail(event.target.value.toLowerCase())}
              required
            />
            <label htmlFor="email">Email</label>
            <span className="helper-text" data-error="Некорректный email" data-success="">
              Введите email
            </span>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12">
            <input
              id="password"
              type="password"
              name="password"
              className="validate"
              minLength="8"
              value={password}
              onChange={(event) => changePassword(event.target.value)}
              required
            />
            <label htmlFor="password">Пароль</label>
            <span className="helper-text" data-error="Короткий пароль" data-success="">
              Введите пароль (минимум 8 символов)
            </span>
          </div>
        </div>
        <div className="col s12">
          <span className="helper-text red-text" data-success="">
            {message}
          </span>
        </div>
        <div className="row">
          <button
            className="btn waves-effect orange waves-light authButton"
            type="submit"
            disabled={isFetching}
          >
            Регистрация
          </button>
          <Link onClick={clear} to="/login" className="btn-outline btn-reg orange-text">
            Есть аккаунт?
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Registration;
