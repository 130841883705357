const userData = JSON.parse(localStorage.getItem("userData"));

const initialState = {
  token: userData ? userData.token : null,
  userId: userData ? userData.userId : null,
};

const SET_TOKEN = "SET_TOKEN";
const SET_USER_ID = "SET_USER_ID";

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case SET_USER_ID:
      return {
        ...state,
        userId: action.userId,
      };

    default:
      return state;
  }
};

const setToken = (token) => ({
  type: SET_TOKEN,
  token,
});

const setUserId = (userId) => ({
  type: SET_USER_ID,
  userId,
});

export const login = (token, userId) => (dispatch) => {
  dispatch(setToken(token));
  dispatch(setUserId(userId));
  localStorage.setItem(
    "userData",
    JSON.stringify({
      userId,
      token,  
    })
  );
};

export const logout = () => (dispatch) => {
  dispatch(setToken(null));
  dispatch(setUserId(null));
  localStorage.removeItem("userData");
};

export default authReducer;
