import React from 'react'
import FormControl from '@mui/material/FormControl'
import { FormHelperText, Input as MuiInput, InputLabel } from '@mui/material'

const Input = (props) => {
  return (
    <FormControl
      value={props.value}
      onChange={props.onChange}
      disabled={!!props.disabled}
      fullWidth={props.fullWidth ? props.fullWidth : true}
      required={props.required ? props.required : false}
      sx={props.sx ? props.sx : {}}
      size={props.size ? props.size : 'medium'}
      margin={props.margin ? props.margin : 'normal'}
      variant={props.variant ? props.variant : 'standard'}
      type={props.type}
    >
      <InputLabel
        htmlFor={props.id}
        style={{ marginLeft: '-10px', fontSize: '12px' }}
      >
        {props.label}
      </InputLabel>
      <MuiInput
        value={props.value}
        name={props.name}
        type={props.type ? props.type : 'text'}
        id={props.id}
        aria-describedby={`${props.id}-helper`}
        disableUnderline={true}
        inputProps={{
          minLength: props.type === 'password' ? 8 : undefined,
          min: props.min,
        }}
        autoComplete="off"
      ></MuiInput>
      <FormHelperText id={`${props.id}-helper`}>
        {props.helperText}
      </FormHelperText>
    </FormControl>
  )
}

export default Input
