import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import AuthPageContainer from "./pages/AuthPage/AuthPageContainer";
import CabinetPageContainer from "./pages/CabinetPage/CabinetPageContainer";
import MainPage from "./pages/MainPage/MainPage";

const Routes = (props) => {
  if (!!props.token) {
    return (
      <React.Fragment>  
        <Route path="/cabinet" exact component={CabinetPageContainer} />
        <Route path="/" exact component={MainPage} />
        <Redirect to="/" />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Route path="/login" exact component={AuthPageContainer} />
        <Route path="/registration" exact component={AuthPageContainer} />
        <Route path="/forgot-password" exact component={AuthPageContainer} />
        <Redirect to="/login" />
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps)(Routes);
