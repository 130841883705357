import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core'
import React from 'react'
import menuProps from '../../../data/menuProps'

const SingleSelect = (props) => {
  const { id, name, options, selected, label, children, onChange, textClassName } = props
  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        id={`${id}`}
        className="select"
        name={name}
        labelId={`${id}-label`}
        label={label}
        value={selected}
        onChange={onChange}
        MenuProps={menuProps}
      >
        {children}
        {options.map((o) => {
          return (
            <MenuItem className={textClassName} key={o} value={o}>
              <ListItemText >{o}</ListItemText>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default SingleSelect
