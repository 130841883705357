import "./App.scss";
import "materialize-css/dist/css/materialize.min.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { Provider } from "react-redux";
import store from "./redux/store";

function App() {

  return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="app">
            <Routes />
          </div>
        </BrowserRouter>
      </Provider>
  );
}



export default App;
