import React, { useState } from 'react'
import './Task.scss'
import { Modal } from '@material-ui/core'

const Task = (props) => {
  const {
    characteristiсId,
    period,
    id,
    checked,
    options,
    changeTaskChecked,
    target,
    description,
    material,
    currentSet,
  } = props
  const elementId = `${characteristiсId}${period}${id}`

  const [modalVisability, setModalVisability] = useState(false)
  const handleOpen = () => {
    setModalVisability(true)
  }
  const handleClose = () => {
    setModalVisability(false)
  }

  const badResultChangeHandler = () => {
    changeTaskChecked(characteristiсId, period, id, false)
  }

  const goodResultChangeHandler = () => {
    changeTaskChecked(characteristiсId, period, id, true)
  }

  return (
    <React.Fragment>
      <Modal
        open={modalVisability}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="my-modal-wrapper" onClick={handleClose}>
          <div
            className="my-modal-content"
            onClick={(event) => event.stopPropagation()}
          >
            <h5 id="modal-modal-title">Цель - {target}</h5>
            <p>Материал: {material}</p>
            <p id="modal-modal-description">
              Описание диагностической ситуации: <br /> {description}
            </p>
          </div>
        </div>
      </Modal>

      <div className="col s12 task">
        <h6 className="task__title">
          Задание № {id}
          <a href='##' onClick={handleOpen} className='orange-text task__helper-text'>
            {/* <HelpIcon className="orange-text" /> */} Диагностика - показать
          </a>
        </h6>

        <fieldset name={'task' + elementId} className="task__fieldset ">
          <p className="task__bad-result">
            <label>
              <input
                className="with-gap"
                name={'task' + elementId}
                checked={!checked}
                type="radio"
                onChange={badResultChangeHandler}
                disabled={currentSet !== period}
              />
              <span>{options[0]}</span>
            </label>
          </p>
          <p className='task__good-result'>
            <label>
              <input
                className="with-gap"
                name={'task' + elementId}
                checked={checked}
                type="radio"
                onChange={goodResultChangeHandler}
                disabled={currentSet !== period}
              />
              <span>{options[1]}</span>
            </label>
          </p>
        </fieldset>
      </div>
    </React.Fragment>
  )
}

export default Task
